import React, { Component } from 'react';
import './Services.css'

class Services extends Component {
  render() {
    return (
        <div className='page'>
          <div className="page_title">
            <h1>Services</h1>
          </div>
            <div className='cert_stmnt'>
              <h2>Certification Statement</h2>
              <p>The certification mark certifies that the solar photovoltaic panels
               and related products provided have met applicable quality, performance
               and/or safety standards compliant with all or specific sections of one
               or more international and national test standards including: IEC 61215,
                IEC 61730, UL 1703, UL 61730, CSA C61215, IEC 61853, IEC 62670, ASTM
                E1036, ASTM E2527, ASTM E2848, IEC 61724. SolarPTL performs various
                performance, quality and safety tests of solar photovoltaic products.
                The tests include indoor accelerated quality tests, indoor accelerated
                safety tests, indoor performance tests, outdoor performance tests,
                outdoor quality tests and outdoor safety tests. All these tests are
                performed in compliance with one or more of the test standards identified
                above. As a follow-up service, throughout the lifetime of a SolarPTL
                certification, SolarPTL regularly audits a manufacturer’s production
                facilities and products to determine continued compliance with the
                applicable test standards’ requirements, accreditors’ requirements and
                SolarPTL’s requirements.</p>

                <p>SolarPTL is a Nationally Recognized Testing Laboratory (NRTL) by OSHA.
                 SolarPTL is also accredited by the American Association for Laboratory
                 Accreditation (A2LA).</p>
              </div>
              <div className='tab_box1'>
                <h2>Standards for Photovoltaic Modules</h2>
                <div className='temp_cont'></div>
              </div>
              <div className='module_tests'>
                <h2>PV module tests conducted at SolarPTL</h2>
                <div className='text_box'>
                  <h3>IEC Qualification Standard</h3>
                  <p>IEC 61215 is a quality standard for design qualification and type approval of terrestrial, flat-plate photovoltaic modules. A typical IEC 61215 program takes 60-90 days from module receipt to completion. Typically, ten modules are needed for IEC qualification testing.</p>
                </div>
                <div className='text_box'>
                  <h3>Sandia Module Characterization</h3>
                  <p>Sandia National Laboratories’ PV module characterization procedure was transferred to PTL in 2006. This effort was intended to meet industry requests for independent module performance testing that provides the parameters (coefficients) required by the Sandia performance model. The performance model and outdoor measurement procedures, developed by Sandia, have been validated by the National Institute for Standards and Technology (NIST) and PTL.</p>
                </div>
                <div className='solo_text_box'>
                  <h3>Performance testing for IEC 61853 and local incentive programs</h3>
                  <p>SolarPTL can test PV modules at multiple-temperature and multiple-irradiance levels per IEC 61853 standard. SolarPTL can test the modules outdoors to determine its power output at Standard Test Conditions (STC), Nominal Module Operating Temperature (NMOT) and low irradiance (200 W/m2); and can determine the temperature coefficients associated with the module in its operating range. These measurements can be generated into a report for use by organizations like the California Energy Commission for its incentive programs.</p>
                </div>
                <div className='text_box'>
                  <h3>Long-Term Outdoor Exposure</h3>
                  <p>SolarPTL also performs long-term Outdoor Exposure for many manufacturers. The lab enjoys an average daily insolation of 6.5 kWh/m2/day at latitude tilt. In a typical program, modules are mounted at latitude tilt and measured quarterly, near the Equinoxes and Solstices. A year-long program includes the module setup, weather data, initial performance measurement with temperature coefficient determination, and quarterly IV’s.</p>
                </div>
                <div className='text_box'>
                  <h3>Accelerated Environmental Stress</h3>
                  <p>SolarPTL has environmental chambers capable of performing thermal cycling (-40°C to 85°C, dry air), damp heat exposure (85°C, 85% RH), UV radiation exposure (at 60°C typical), and humdity freeze (-40°C, dry to 85°C,85% RH). Thermal cycling and damp heat stress tests take about 45 days. Humidity Freeze test takes 10 days.</p>
                </div>



              </div>

        </div>

    );
  }
}
export default Services;
