import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './Register.css'

class Register extends Component {
  render() {
    return (
      <div className="container">
        <div className="reg_page_title ">
          <h1>Register</h1>
        </div>
        <div className="reg_body">
          <form action="index" method="post" autocomplete="off" className="col l12 s12">
            <div className="row">
              <p id="subtit2">Account Information</p>
            </div>
            <div className="row">
              <div className="input-field">
                <label for="username" data-error="Username must be 8 characters long">Username:</label>
                <input type="text" required  name='username' className="validate" id="username" data-length="8"></input>
              </div>
            </div>
            <div className="row">
              <div className="input-field">
                <label for="password" data-error="Password must be at least 8 characters long comprising at least a digit, a lowercase letter, and an uppercase letter">Password:</label>
                <input type="password" required autocomplete="off" className="validate"></input>
              </div>
            </div>
            <div className="row">
              <div className="input-field">
                <label for="password" data-error="Password must be at most 8 characters long comprising at least a digit, a lowercase letter, and an uppercase letter">Verify Password:</label>
                <input type="password" required autocomplete="off" className="validate" data-length="8"></input>
              </div>
            </div>
            <div className="row">
            <p id="subtit2">Personal Information</p>
            </div>
            <div className="row">
            <label>Salutation:</label>
            <div className="input-field">
              <select required>
                <option value="" disabled selected></option>
                <option value="1">Mr.</option>
                <option value="2">Mrs.</option>
                <option value="3">Ms.</option>
                <option value="4">Dr.</option>
              </select>
            </div>
            </div>
            <div className="row">
            <div className="input-field">
              <label for="first" >First Name:</label>
              <input type="text" required name='first' className="validate" id="first"></input>
            </div>
            </div>
            <div className="row">
            <div className="input-field">
              <label for="middle">Middle Name:</label>
              <input type="text" name='middle' className="validate" id="middle"></input>
            </div>
            </div>
            <div className="row">
            <div className="input-field">
              <label for="last" data-error="Last Name Required">Last Name:</label>
              <input type="text" required  name='last' className="validate" id="last"></input>
            </div>
            </div>
            <div className="row">
            <div className="input-field">
              <label for="jobTitle" >Job Title:</label>
              <input type="text" required name='jobTitle' className="validate" id="add1"></input>
            </div>
            </div>
            <div className="row">
            <p id="subtit2">Contact Information</p>
            </div>
            <div className="row">
            <div className="input-field">
              <label for="email" data-error="Please enter valid email address">Email</label>
              <input type="email" required name="email" className="validate"></input>
            </div>
            </div>
            <div className="row">
            <div className="input-field">
              <label for="officePhone" >Office Phone:</label>
              <input type="text" name='officePhone' className="validate" id="officePhone"></input>
            </div>
            </div>
            <div className="row">
            <div className="input-field">
              <label for="cellPhone">Cell Phone:</label>
              <input type="text" required name='cellPhone' className="validate" id="cellPhone"></input>
            </div>
            </div>
            <div className="row">
              <div className="button-div">
                <button className="reg-btn" name="login" type="submit" value="Submit">Next</button>
              </div>
            </div>
            <div className="row">
              <p id="">By continuing registration, you agree to this site's <NavLink to="#">Terms & Conditions</NavLink>.</p>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default Register;
