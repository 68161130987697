import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './Foot.css';

class Foot extends Component {
  render() {
    return (
      <div className="foot_container">

        <div className="top_footer-row">
          <div className="center">

            <div className="foot-col">
                <div className="col-content">
                    <p>About us</p>
                    <div className="textwidget">
                        <span>Welcome to SolarPTL &#8211;</span><br/>
                        <span>Your #1 provider of solar PV certification testing services!</span>
                    </div>
                </div>
            </div>

            <div className="foot-col">
                <div className="col-content">
                    <p>Keep in touch</p>
                        <div className="textwidget">
                            <ul>
                                <li><i className="hb-moon-office"></i><span>SolarPTL, LLC</span></li>
                                <li><i className="hb-moon-location-4"></i><span>1107 West Fairmont Drive<br/> Tempe, AZ 85282</span></li>
                                <li><i className="hb-moon-phone-2"></i><span><a href="tel:+1 (480) 966-1700">+1 (480) 966-1700</a></span></li>
                                <li><i className="icon-envelope-alt"></i><span><a href="mailto:i&#110;fo&#64;&#115;&#111;&#108;&#97;&#114;&#112;tl&#46;&#99;&#111;m">in&#102;o&#64;s&#111;l&#97;&#114;p&#116;&#108;.c&#111;m</a></span></li>	<li><i className="hb-moon-earth"></i><span><a href="www.solarptl.com" target="_blank">www.solarptl.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="foot-col">
                    <div className="col-content">
                        <p>Business Hours <br/>(Arizona time)</p>
                        <div className="textwidget">

                            <div className="day">
                                <div className="two-col1">
                                    <p>Monday:</p>
                                </div>
                                <div className="two-col2">
                                    <p>8:00AM &#8211; 6:00PM</p>
                                </div>
                            </div>

                            <div className="day">
                                <div className="two-col1">
                                    <p>Tuesday:</p>
                                </div>
                                <div className="two-col2">
                                    <p>8:00AM &#8211; 6:00PM</p>
                                </div>
                            </div>

                            <div className="day">
                                <div className="two-col1">
                                    <p>Wednesday:</p>
                                </div>
                                <div className="two-col2">
                                    <p>8:00AM &#8211; 6:00PM</p>
                                </div>
                            </div>

                            <div className="day">
                                <div className="two-col1">
                                    <p>Thursday:</p>
                                </div>
                                <div className="two-col2">
                                    <p>8:00AM &#8211; 6:00PM</p>
                                </div>
                            </div>

                            <div className="day">
                                <div className="two-col1">
                                    <p>Friday:</p>
                                </div>
                                <div className="two-col2">
                                    <p>8:00AM &#8211; 6:00PM</p>
                                </div>
                            </div>


                        </div>



                     </div>
                </div>

                <div className="foot-col">
                    <div className="col-content_last">
                        <p>Follow Us</p>
                        <div className="textwidget">
                            <ul className="social-icons">

                                <li className="facebook">
                                        <a href="http://facebook.com/hbthemes" original-title="Facebook">
                                            <i className="hb-moon-facebook"></i>
                                            <i className="hb-moon-facebook"></i>
                                        </a>
                                </li>

                                <li className="linkedin">
                                        <a href="http://linkedin.com/" original-title="Linkedin">
                                            <i className="hb-moon-linkedin"></i>
                                            <i className="hb-moon-linkedin"></i>
                                        </a>
                                </li>

                                <li className="twitter">
                                        <a href="http://twitter.com/" original-title="Twitter">
                                            <i className="hb-moon-twitter"></i>
                                            <i className="hb-moon-twitter"></i>
                                        </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className="foot-nav">
            <div className="bot_center">

                <div className="bot-col-a">
                    <div className="foot_navbar">
                        <NavLink to="/">Home</NavLink>
                        <NavLink to="/services">Services</NavLink>
                        <NavLink to="/certificates">Certificates</NavLink>
                        <NavLink to="/about-us">About Us</NavLink>
                        <NavLink to="/pv-modules">PV Modules</NavLink>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                    </div>
                </div>

                <div className="bot-col-b">
                    <div className="cr">
                        <p>2019 - SolarPTL.com</p>
                    </div>
                </div>

            </div>
        </div>

      </div>
    );
  }
}

export default Foot;
