import React, {Component} from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';

class SolarForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            errorMessage : false,
            successMessage : false
        }
    }

    componentWillUnmount(){
        this.setState({
            errorMessage : false,
            successMessage : false
        })
    }

    render() {
        let initialValues = this.props.initialValues;
        const validationsItems = [
            "modelNumber",
            "lengthMod",
            "width",
            "height",
            "productArea",
            "downward",
            "up",
            "orientation",
            "size",
            "number",
            "manualVersion",
            "downslope"

        ]

        const MyTextInput = ({label, ...props}) => {
            // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
            // which we can spread on <input> and alse replace ErrorMessage entirely.
            const [field, meta] = useField(props);
            let inputClass;

            if (meta.touched && meta.error && (validationsItems.indexOf(props.name) !== -1)) {
                inputClass = "required field error";
            } else if (validationsItems.indexOf(props.name) !== -1) {
                inputClass = "required field";
            } else {
                inputClass = "field";
            }
            return (
                <>
                    <div className={inputClass}>
                        <label htmlFor={props.id || props.name}>{label}</label>
                        <input className="text-input" {...field} {...props} />
                    </div>
                    {meta.touched && meta.error ? (
                        <div className="ui red basic label">{meta.error}</div>
                    ) : null}
                </>
            );
        };

        let message;
        if (this.state.errorMessage && !this.state.successMessage){
            message =  <div className="ui negative message message-display-true">
                <div className="header">
                    Record Already Present! Please enter different Model Number Value.
                </div>
            </div>;
        } else if (!this.state.errorMessage && this.state.successMessage){
            message = <div className="ui success message message-display-true">
                <div className="header">
                    Form Successfully Submitted!
                </div>
            </div>;
        }

        // And now we can use these
        return (
                <>
                    <h1 className="ui header">Enter New Model Details</h1>
                    <Formik
                        initialValues={{
                            modelNumber: '',
                            lengthModMod: initialValues.lengthModMod,
                            width: initialValues.width,
                            height: initialValues.height,
                            productArea: initialValues.productArea,
                            softwareVersion: initialValues.softwareVersion,
                            clampLoad: initialValues.clampLoad,
                            slipFactor: initialValues.slipFactor,
                            stressArea: initialValues.stressArea,
                            downward: initialValues.downward,
                            up: initialValues.up,
                            downslope: initialValues.downslope,
                            size: initialValues.size,
                            orientation: initialValues.orientation,
                            number: initialValues.number,
                            manualVersion: initialValues.manualVersion,
                            otherspecifications: initialValues.otherspecifications,
                            updated_by: initialValues.updated_by,
                            product: initialValues.product
                        }}
                        validationSchema={Yup.object({
                            modelNumber: Yup.string()
                                .required('Required'),
                            lengthMod: Yup.string()
                                .required('Required'),
                            width: Yup.string()
                                .required('Required'),
                            height: Yup.string()
                                .required('Required'),
                            productArea: Yup.string()
                                .required('Required'),
                            downward: Yup.string()
                                .required('Required'),
                            up: Yup.string()
                                .required('Required'),
                            orientation: Yup.string()
                                .required('Required'),
                            size: Yup.string()
                                .required('Required'),
                            number: Yup.string()
                                .required('Required'),
                            manualVersion: Yup.string()
                                .required('Required'),
                            downslope: Yup.string()
                                .required('Required')
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/CDF/', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Basic dmNoYWNrbzpBZG1pbkAxMjM='
                                },
                                body: JSON.stringify(values)
                            })
                                .then(response => {
                                    response.json()
                                    if (response.status === 201){
                                        this.setState({
                                            errorMessage : false,
                                            successMessage : true
                                        })
                                    } else {
                                        this.setState({
                                            errorMessage : true,
                                            successMessage : false
                                        })
                                    }

                                })
                                .then(data => {
                                    console.log('error not:');
                                    console.log(data)

                                })
                                .catch(error => {
                                    console.log('error:');
                                    console.error(error)
                                })
                        }}
                    >
                        <Form className="ui form">
                            <MyTextInput
                                label="Model Name/Number"
                                name="modelNumber"
                                type="text"
                                placeholder="Enter the name of the model"
                            />
                            <MyTextInput
                                label="Length of model[mm]"
                                name="lengthMod"
                                type="text"
                                placeholder="Enter Length of model[mm]"
                            />
                            <MyTextInput
                                label="Width of the model[mm]"
                                name="width"
                                type="text"
                                placeholder="Enter Width of model[mm]"
                            />

                            <MyTextInput
                                label="Height of the model[mm]"
                                name="height"
                                type="text"
                                placeholder="Enter Height of model[mm]"
                            />

                            <MyTextInput
                                label="Product area [m²] (max assembled unit)"
                                name="productArea"
                                type="text"
                                placeholder="Enter Product area [m²] (max assembled unit)"
                            />

                            <MyTextInput
                                label="Software version (if applicable)"
                                name="softwareVersion"
                                type="text"
                                placeholder="Enter Software version (if applicable)"
                            />
                            <MyTextInput
                                label="Fasteners Clamp Load (if applicable)"
                                name="clampLoad"
                                type="text"
                                placeholder="Enter Fasteners Clamp Load (if applicable)"
                            />
                            <MyTextInput
                                label="Fasteners Slip Factor (if applicable)]"
                                name="slipFactor"
                                type="text"
                                placeholder="Enter Fasteners Slip Factor (if applicable)"
                            />
                            <MyTextInput
                                label="Threaded Rod Stress Area (if applicable)"
                                name="stressArea"
                                type="text"
                                placeholder="Enter Threaded Rod Stress Area (if applicable)"
                            />

                            <MyTextInput
                                label="Mechanical Design Load Downward/Pos(Snow) [psf]"
                                name="downward"
                                type="text"
                                placeholder="Enter Mechanical Design Load Downward/Pos(Snow) [psf]"
                            />

                            <MyTextInput
                                label="Mechanical Design Load upward Up/Neg(Wind)[psf]"
                                name="up"
                                type="text"
                                placeholder="Enter Mechanical Design Load upward Up/Neg(Wind)[psf]"
                            />

                            <MyTextInput
                                label="Mechanical Design Load Downslope [psf]"
                                name="downslope"
                                type="text"
                                placeholder="Enter Mechanical Design Load Downslope (if applicable)[psf]"
                            />
                            <MyTextInput
                                label="System Module Load Rating Size"
                                name="size"
                                type="text"
                                placeholder="Enter System Module Load Rating Size"
                            />
                            <MyTextInput
                                label="System Module Load Rating Orientation"
                                name="orientation"
                                type="text"
                                placeholder="Enter System Module Load Rating Orientation"
                            />
                            <MyTextInput
                                label="System Module Load Rating Quantity"
                                name="number"
                                type="text"
                                placeholder="Enter System Module Load Rating Quantity"
                            />
                            <MyTextInput
                                label="Installation Manual Version"
                                name="manualVersion"
                                type="text"
                                placeholder="Enter Installation Manual Version"
                            />

                            <MyTextInput
                                label="Other specifications"
                                name="otherspecifications"
                                type="text"
                                placeholder="Enter other specifications (if any)"
                            />

                            <br/>
                            <button type="submit" className="ui button primary">Submit</button>
                            {message}
                        </Form>
                    </Formik>
                </>
            )
        }
    }
export default SolarForm;