import React, { Component } from 'react';
import './Dashboard.css';
import {NavLink} from "react-router-dom";


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        displayed_form: '',
        logged_in: localStorage.getItem('token') ? true : false,
        username:''}
    }

  componentDidMount() {
    if (this.state.logged_in) {
      fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/core/current_user/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        // .then(this.handleErrors)
        .then(json => {
          this.setState({ username: json.username });
        })
        // .catch(localStorage.removeItem('token'))
        .catch(error => console.log(error))
    }
  }

  handleErrors(response) {
    if (!response.ok) {
      localStorage.removeItem('token');
    }
    return response;
  }

  render() {
    let form;
    if(!this.state.logged_in){
      form = <li>Please log-in to view this content.</li>
    } else{
      form = <div class="dashboard-main"><div class="form-inline">{this.state.logged_in
        ? `Hello ${this.props.first_name} !`
        : ''}</div><div className='page'>
        <div className="page_title">
          <h1>Dashboard</h1>
        </div>
          <p>Welcome to the Dashboard!</p>
          <ul>
          <li><NavLink to="/cdf">Constructional Data Form</NavLink> </li>
          <li><NavLink to="/sdf">Similarity Declaration Form</NavLink></li>
          </ul>
      </div></div>;
    }
    return (
      <div>{form}</div>
    );
  }
}
export default Dashboard;
