import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';

class PVmodule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: false,
            successMessage: false
        }
    }

    componentWillUnmount() {
        this.setState({
            errorMessage: false,
            successMessage: false
        })
    }

    render() {
        let initialValues = this.props.initialValues;

        const validationsItems = [
            "modelNumber",
            "lengthMod",
            "width",
            "height",
            "productArea",
            "moduleTech",
            "numberofCells",
            "appClass",
            "fireProtectionClass",
            "frontCoverprocess",
            "frontCoverthickness",
            "frontcoverManf",
            "frontCoverType",
            "backCoverThickness",
            "backCover",
            "backcoverManf",
            "backCoverType",
            "encapsulationMaterial",
            "encapsulationcoverManf",
            "encapsulationCoverType",
            "framepartsmaterial",
            "framepartsheight",
            "framepartswidth",
            "framepartslen",
            "framepartscoverManf",
            "framepartsCoverType",
            "adhesive",
            "adhesiveManf",
            "adhesiveType",
            "solarCellMaterial",
            "solarCelllen",
            "solarCellwidth",
            "solarCellheight",
            "solarCelleManf",
            "serialParallel",
            "solarCellType",
            "noOfSolarCells",
            "ParallelCell",
            "serialCell",
            "bypassDiode",
            "bypassDiodeManf",
            "bypassDiodeType",
            "bypassDiodeTemp",
            "cellConnector",
            "cellConnectorWidth",
            "cellConnectorThickness",
            "cellConnectorManf",
            "cellConnectorType",
            "stringConnectorManf",
            "stringConnectorType",
            "stringConnector",
            "connectorManf",
            "connectorType",
            "stringConnectorWidth",
            "stringConnectorThickness",
            "solderingMaterial",
            "solderingMaterialManf",
            "solderingMaterialType",
            "fluxingAgent",
            "fluxingAgentManf",
            "fluxingAgentType",
            "cable",
            "cableManf",
            "cableType",
            "cableVoltage",
            "cablearea",
            "connectedRatedCurrent",
            "connectedRatedVoltage",
            "junctionBoxRatedVoltage",
            "junctionBoxRatedCurrent",
            "junctionBox",
            "junctionBoxadhesive",
            "pottingMaterial",
            "fixingTapeComp",
            "fixingTapeWidth",
            "insulationComposite",
            "insulationWidth",
            "insulationType",
            "insulationManf",
            "fixingTapeManf",
            "fixingTapeType",
            "pottingMaterialManf",
            "pottingMaterialType",
            "junctionBoxadhesiveManf",
            "junctionBoxadhesiveType",
            "connectorManf",
            "connectorType",
            "junctionBoxManf",
            "junctionBoxType",
        ]

        const MyTextInput = ({label, ...props}) => {
            const [field, meta] = useField(props);
            let inputClass;

            if (meta.touched && meta.error && (validationsItems.indexOf(props.name) !== -1)) {
                inputClass = "required field error";
            } else if (validationsItems.indexOf(props.name) !== -1) {
                inputClass = "required field";
            } else {
                inputClass = "field";
            }
            return (
                <>
                    <div className={inputClass}>
                        <label htmlFor={props.id || props.name}>{label}</label>
                        <input className="text-input" {...field} {...props} />
                    </div>
                    {meta.touched && meta.error ? (
                        <div className="ui red basic label">{meta.error}</div>
                    ) : null}
                </>
            );
        };

        let message;
        if (this.state.errorMessage && !this.state.successMessage) {
            message = <div className="ui negative message message-display-true">
                <div className="header">
                    Record Already Present! Please enter different Model Number Value.
                </div>
            </div>;
        } else if (!this.state.errorMessage && this.state.successMessage) {
            message = <div className="ui success message message-display-true">
                <div className="header">
                    Form Successfully Submitted!
                </div>
            </div>;
        }


        return (
            <>
                <h1 className="ui header">Enter New Model Details</h1>
                <Formik
                    initialValues={{
                        modelNumber: '',
                        lengthMod: initialValues.lengthMod,
                        width: initialValues.width,
                        height: initialValues.height,
                        productArea: initialValues.productArea,
                        moduleTech: initialValues.moduleTech,
                        numberofCells: initialValues.numberofCells,
                        appClass: initialValues.appClass,
                        fireProtectionClass: initialValues.fireProtectionClass,
                        note: initialValues.note,
                        frontCoverprocess: initialValues.frontCoverprocess,
                        frontCoverthickness: initialValues.frontCoverthickness,
                        frontcoverManf: initialValues.frontcoverManf,
                        frontCoverType: initialValues.frontCoverType,
                        frontCoverCertificate: initialValues.frontCoverCertificate,
                        frontCoverStandard: initialValues.frontCoverStandard,
                        backCoverThickness: initialValues.backCoverThickness,
                        backCover: initialValues.backCover,
                        backcoverManf: initialValues.backcoverManf,
                        backCoverType: initialValues.backCoverType,
                        backCoverStandard: initialValues.backCoverStandard,
                        backCoverCertificate: initialValues.backCoverCertificate,
                        encapsulationMaterial: initialValues.encapsulationMaterial,
                        encapsulationcoverManf: initialValues.encapsulationcoverManf,
                        encapsulationCoverType: initialValues.encapsulationCoverType,
                        encapsulationCoverStandard: initialValues.encapsulationCoverStandard,
                        encapsulationCoverCertificate: initialValues.encapsulationCoverCertificate,
                        framepartsmaterial: initialValues.framepartsmaterial,
                        framepartsheight: initialValues.framepartsheight,
                        framepartswidth: initialValues.framepartswidth,
                        framepartslen: initialValues.framepartslen,
                        framepartscoverManf: initialValues.framepartscoverManf,
                        framepartsCoverType: initialValues.framepartsCoverType,
                        framepartsCoverStandard: initialValues.framepartsCoverStandard,
                        framepartsCoverCertificate: initialValues.framepartsCoverCertificate,
                        adhesive: initialValues.adhesive,
                        adhesiveManf: initialValues.adhesiveManf,
                        adhesiveType: initialValues.adhesiveType,
                        adhesiveStandard: initialValues.adhesiveStandard,
                        adhesiveCertificate: initialValues.adhesiveCertificate,
                        solarCellMaterial: initialValues.solarCellMaterial,
                        solarCelllen: initialValues.solarCelllen,
                        solarCellwidth: initialValues.solarCellwidth,
                        solarCellheight: initialValues.solarCellheight,
                        solarCelleManf: initialValues.solarCelleManf,
                        solarCellType: initialValues.solarCellType,
                        solarCellStandard: initialValues.solarCellStandard,
                        solarCellCertificate: initialValues.solarCellCertificate,
                        noOfSolarCells: initialValues.noOfSolarCells,
                        noOfsolarCellStandard: initialValues.noOfsolarCellStandard,
                        noOfsolarCellCertificate: initialValues.noOfsolarCellCertificate,
                        bypassDiode: initialValues.bypassDiode,
                        bypassDiodeRating: initialValues.bypassDiodeRating,
                        bypassDiodeTemp: initialValues.bypassDiodeTemp,
                        bypassDiodeManf: initialValues.bypassDiodeManf,
                        bypassDiodeType: initialValues.bypassDiodeType,
                        bypassDiodeStandard: initialValues.bypassDiodeStandard,
                        bypassDiodeCertificate: initialValues.bypassDiodeCertificate,
                        cellbypassDiodeStandard: initialValues.cellbypassDiodeStandard,
                        cellbypassDiodeCertificate: initialValues.cellbypassDiodeCertificate,
                        serialParallel: initialValues.serialParallel,
                        noOfDiodeStandard: initialValues.noOfDiodeStandard,
                        noOfDiodeCertificate: initialValues.noOfDiodeCertificate,
                        serialCell: initialValues.serialCell,
                        ParallelCell: initialValues.ParallelCell,
                        serialParallelStandard: initialValues.serialParallelStandard,
                        serialParallelCertificate: initialValues.serialParallelCertificate,
                        cellConnector: initialValues.cellConnector,
                        cellConnectorWidth: initialValues.cellConnectorWidth,
                        cellConnectorThickness: initialValues.cellConnectorThickness,
                        cellConnectorManf: initialValues.cellConnectorManf,
                        cellConnectorType: initialValues.cellConnectorType,
                        cellConnectorStandard: initialValues.cellConnectorStandard,
                        cellConnectorCertificate: initialValues.cellConnectorCertificate,
                        stringConnectorManf: initialValues.stringConnectorManf,
                        stringConnectorType: initialValues.stringConnectorType,
                        stringConnectorStandard: initialValues.stringConnectorStandard,
                        stringConnectorCertificate: initialValues.stringConnectorCertificate,
                        stringConnector: initialValues.stringConnector,
                        stringConnectorWidth: initialValues.stringConnectorWidth,
                        stringConnectorThickness: initialValues.stringConnectorThickness,
                        solderingMaterial: initialValues.solderingMaterial,
                        solderingMaterialManf: initialValues.solderingMaterialManf,
                        solderingMaterialType: initialValues.solderingMaterialType,
                        solderingMaterialStandard: initialValues.solderingMaterialStandard,
                        solderingMaterialCertificate: initialValues.solderingMaterialCertificate,
                        fluxingAgent: initialValues.fluxingAgent,
                        fluxingAgentManf: initialValues.fluxingAgentManf,
                        fluxingAgentType: initialValues.fluxingAgentType,
                        fluxingAgentStandard: initialValues.fluxingAgentStandard,
                        fluxingAgentCertificate: initialValues.fluxingAgentCertificate,
                        cableManf: initialValues.cableManf,
                        cableType: initialValues.cableType,
                        cableStandard: initialValues.cableStandard,
                        cableCertificate: initialValues.cableCertificate,
                        cable: initialValues.cable,
                        cableVoltage: initialValues.cableVoltage,
                        cablearea: initialValues.cablearea,
                        connectedRatedCurrent: initialValues.connectedRatedCurrent,
                        connectedRatedVoltage: initialValues.connectedRatedVoltage,
                        junctionBoxRatedVoltage: initialValues.junctionBoxRatedVoltage,
                        junctionBoxRatedCurrent: initialValues.junctionBoxRatedCurrent,
                        junctionBox: initialValues.junctionBox,
                        junctionBoxadhesive: initialValues.junctionBoxadhesive,
                        pottingMaterial: initialValues.pottingMaterial,
                        fixingTapeComp: initialValues.fixingTapeComp,
                        fixingTapeWidth: initialValues.fixingTapeWidth,
                        insulationComposite: initialValues.insulationComposite,
                        insulationWidth: initialValues.insulationWidth,
                        connectorManf: initialValues.connectorManf,
                        connectorType: initialValues.connectorType,
                        connectorStandard: initialValues.connectorStandard,
                        connectorCertificate: initialValues.connectorCertificate,
                        junctionBoxManf: initialValues.junctionBoxManf,
                        junctionBoxType: initialValues.junctionBoxType,
                        junctionBoxStandard: initialValues.junctionBoxStandard,
                        junctionBoxCertificate: initialValues.junctionBoxCertificate,
                        insulationCertificate: initialValues.insulationCertificate,
                        insulationStandard: initialValues.insulationStandard,
                        insulationType: initialValues.insulationType,
                        insulationManf: initialValues.insulationManf,
                        fixingTapeManf: initialValues.fixingTapeManf,
                        fixingTapeType: initialValues.fixingTapeType,
                        fixingTapeStandard: initialValues.fixingTapeStandard,
                        fixingTapeCertificate: initialValues.fixingTapeCertificate,
                        pottingMaterialManf: initialValues.pottingMaterialManf,
                        pottingMaterialType: initialValues.pottingMaterialType,
                        pottingMaterialStandard: initialValues.pottingMaterialStandard,
                        pottingMaterialCertificate: initialValues.pottingMaterialCertificate,
                        junctionBoxadhesiveManf: initialValues.junctionBoxadhesiveManf,
                        junctionBoxadhesiveType: initialValues.junctionBoxadhesiveType,
                        junctionBoxadhesiveStandard: initialValues.junctionBoxadhesiveStandard,
                        junctionBoxadhesiveCertificate: initialValues.junctionBoxadhesiveCertificate,
                        updated_by: initialValues.username,
                        product: initialValues.product
                    }}
                    validationSchema={Yup.object({
                        modelNumber: Yup.string()
                            .required('Required'),
                        lengthMod: Yup.string()
                            .required('Required'),
                        width: Yup.string()
                            .required('Required'),
                        height: Yup.string()
                            .required('Required'),
                        productArea: Yup.string()
                            .required('Required'),
                        moduleTech: Yup.string()
                            .required('Required'),
                        numberofCells: Yup.string()
                            .required('Required'),
                        appClass: Yup.string()
                            .required('Required'),
                        fireProtectionClass: Yup.string()
                            .required('Required'),
                        frontCoverprocess: Yup.string()
                            .required('Required'),
                        frontCoverthickness: Yup.string()
                            .required('Required'),
                        frontcoverManf: Yup.string()
                            .required('Required'),
                        frontCoverType: Yup.string()
                            .required('Required'),
                        backCoverThickness: Yup.string()
                            .required('Required'),
                        backCover: Yup.string()
                            .required('Required'),
                        backcoverManf: Yup.string()
                            .required('Required'),
                        backCoverType: Yup.string()
                            .required('Required'),
                        encapsulationMaterial: Yup.string()
                            .required('Required'),
                        encapsulationcoverManf: Yup.string()
                            .required('Required'),
                        encapsulationCoverType: Yup.string()
                            .required('Required'),
                        framepartsmaterial: Yup.string()
                            .required('Required'),
                        framepartsheight: Yup.string()
                            .required('Required'),
                        framepartswidth: Yup.string()
                            .required('Required'),
                        framepartslen: Yup.string()
                            .required('Required'),
                        framepartscoverManf: Yup.string()
                            .required('Required'),
                        framepartsCoverType: Yup.string()
                            .required('Required'),
                        ParallelCell: Yup.string()
                            .required('Required'),
                        adhesive: Yup.string()
                            .required('Required'),
                        adhesiveManf: Yup.string()
                            .required('Required'),
                        adhesiveType: Yup.string()
                            .required('Required'),
                        solarCellMaterial: Yup.string()
                            .required('Required'),
                        solarCelllen: Yup.string()
                            .required('Required'),
                        solarCellwidth: Yup.string()
                            .required('Required'),
                        solarCellheight: Yup.string()
                            .required('Required'),
                        solarCelleManf: Yup.string()
                            .required('Required'),
                        solarCellType: Yup.string()
                            .required('Required'),
                        noOfSolarCells: Yup.string()
                            .required('Required'),
                        bypassDiode: Yup.string()
                            .required('Required'),
                        bypassDiodeRating: Yup.string()
                            .required('Required'),
                        bypassDiodeTemp: Yup.string()
                            .required('Required'),
                        bypassDiodeManf: Yup.string()
                            .required('Required'),
                        bypassDiodeType: Yup.string()
                            .required('Required'),
                        serialParallel: Yup.string()
                            .required('Required'),
                        serialCell: Yup.string()
                            .required('Required'),
                        cellConnector: Yup.string()
                            .required('Required'),
                        cellConnectorWidth: Yup.string()
                            .required('Required'),
                        cellConnectorThickness: Yup.string()
                            .required('Required'),
                        cellConnectorManf: Yup.string()
                            .required('Required'),
                        cellConnectorType: Yup.string()
                            .required('Required'),
                        stringConnectorManf: Yup.string()
                            .required('Required'),
                        stringConnectorType: Yup.string()
                            .required('Required'),
                        stringConnector: Yup.string()
                            .required('Required'),
                        stringConnectorWidth: Yup.string()
                            .required('Required'),
                        stringConnectorThickness: Yup.string()
                            .required('Required'),
                        solderingMaterial: Yup.string()
                            .required('Required'),
                        solderingMaterialManf: Yup.string()
                            .required('Required'),
                        solderingMaterialType: Yup.string()
                            .required('Required'),
                        fluxingAgent: Yup.string()
                            .required('Required'),
                        fluxingAgentManf: Yup.string()
                            .required('Required'),
                        fluxingAgentType: Yup.string()
                            .required('Required'),
                        cable: Yup.string()
                            .required('Required'),
                        cableManf: Yup.string()
                            .required('Required'),
                        cableType: Yup.string()
                            .required('Required'),
                        cableVoltage: Yup.string()
                            .required('Required'),
                        cablearea: Yup.string()
                            .required('Required'),
                        connectedRatedCurrent: Yup.string()
                            .required('Required'),
                        connectedRatedVoltage: Yup.string()
                            .required('Required'),
                        junctionBoxRatedVoltage: Yup.string()
                            .required('Required'),
                        junctionBoxRatedCurrent: Yup.string()
                            .required('Required'),
                        junctionBox: Yup.string()
                            .required('Required'),
                        junctionBoxadhesive: Yup.string()
                            .required('Required'),
                        pottingMaterial: Yup.string()
                            .required('Required'),
                        fixingTapeComp: Yup.string()
                            .required('Required'),
                        fixingTapeWidth: Yup.string()
                            .required('Required'),
                        insulationComposite: Yup.string()
                            .required('Required'),
                        insulationWidth: Yup.string()
                            .required('Required'),
                        insulationType: Yup.string()
                            .required('Required'),
                        insulationManf: Yup.string()
                            .required('Required'),
                        fixingTapeManf: Yup.string()
                            .required('Required'),
                        fixingTapeType: Yup.string()
                            .required('Required'),
                        pottingMaterialManf: Yup.string()
                            .required('Required'),
                        pottingMaterialType: Yup.string()
                            .required('Required'),
                        junctionBoxadhesiveManf: Yup.string()
                            .required('Required'),
                        junctionBoxadhesiveType: Yup.string()
                            .required('Required'),
                        connectorManf: Yup.string()
                            .required('Required'),
                        connectorType: Yup.string()
                            .required('Required'),
                        junctionBoxManf: Yup.string()
                            .required('Required'),
                        junctionBoxType: Yup.string()
                            .required('Required'),

                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        console.log(JSON.stringify(values));
                        fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/CDF/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `JWT ${localStorage.getItem('token')}`
                            },
                            body: JSON.stringify(values)
                        })
                            .then(response => {
                                response.json()
                                if (response.status === 201) {
                                    this.setState({
                                        errorMessage: false,
                                        successMessage: true
                                    })
                                } else {
                                    this.setState({
                                        errorMessage: true,
                                        successMessage: false
                                    })
                                }

                            })
                            .then(data => {


                            })
                            .catch(error => {
                                console.log('error:');
                                console.error(error)
                            })
                    }}
                >
                    <Form className="ui form">
                        <MyTextInput
                            label="Model Name/Number"
                            name="modelNumber"
                            type="text"
                            placeholder="Enter the name of the model"
                        />
                        <MyTextInput
                            label="Length of model[mm]"
                            name="lengthMod"
                            type="text"
                            placeholder="Enter Length of model[mm]"
                        />
                        <MyTextInput
                            label="Width of the model[mm]"
                            name="width"
                            type="text"
                            placeholder="Enter Width of model[mm]"
                        />

                        <MyTextInput
                            label="Height of the model[mm]"
                            name="height"
                            type="text"
                            placeholder="Enter Height of model[mm]"
                        />

                        <MyTextInput
                            label="Product area [m²] (max assembled unit)"
                            name="productArea"
                            type="text"
                            placeholder="Enter Product area [m²] (max assembled unit)"
                        />

                        <MyTextInput
                            label="Module technology"
                            name="moduleTech"
                            type="text"
                            placeholder="Enter Module technology"
                        />
                        <MyTextInput
                            label="Number of cells"
                            name="numberofCells"
                            type="text"
                            placeholder="Enter Number of cells"
                        />
                        <MyTextInput
                            label="Application class (for IEC certification)"
                            name="appClass"
                            type="text"
                            placeholder="Enter Application class (for IEC certification)"
                        />
                        <MyTextInput
                            label="Fire protection class (for IEC certification)"
                            name="fireProtectionClass"
                            type="text"
                            placeholder="Enter Fire protection class (for IEC certification)"
                        />

                        <MyTextInput
                            label="Note"
                            name="note"
                            type="text"
                            placeholder="Enter Note"
                        />

                        <MyTextInput
                            label="Front Cover Thickness (mm)"
                            name="frontCoverthickness"
                            type="text"
                            placeholder="Enter Front Cover Thickness (mm)"
                        />

                        <MyTextInput
                            label="Front Cover Process (tempered, heat strengthened)"
                            name="frontCoverprocess"
                            type="text"
                            placeholder="Enter Front Cover Process (tempered, heat strengthened)"
                        />
                        <MyTextInput
                            label="Front Cover Manufacturer Name"
                            name="frontcoverManf"
                            type="text"
                            placeholder="Enter Front Cover Manufacturer Name"
                        />

                        <MyTextInput
                            label="Front Cover Type/Model"
                            name="frontCoverType"
                            type="text"
                            placeholder="Enter Front Cover Type/Model"
                        />
                        <MyTextInput
                            label="Front Cover Standard(if applicable)"
                            name="frontCoverStandard"
                            type="text"
                            placeholder="Enter Front Cover Standard"
                        />
                        <MyTextInput
                            label="Front Cover certificates (if applicable)"
                            name="frontCoverCertificate"
                            type="text"
                            placeholder="Enter Front Cover certificates (if applicable)"
                        />
                        <MyTextInput
                            label="Back Cover Thickness"
                            name="backCoverThickness"
                            type="text"
                            placeholder="Enter Back Cover Thickness"
                        />
                        <MyTextInput
                            label="Back Cover Composite"
                            name="backCover"
                            type="text"
                            placeholder="Enter Back Cover Composite"
                        />
                        <MyTextInput
                            label="Back Cover Manufacturer Name"
                            name="backcoverManf"
                            type="text"
                            placeholder="Enter Back Cover Manufacturer Name"
                        />

                        <MyTextInput
                            label="Back Cover Type/Model"
                            name="backCoverType"
                            type="text"
                            placeholder="Enter Back Cover Type/Model"
                        />
                        <MyTextInput
                            label="Back Cover Standard(if applicable)"
                            name="backCoverStandard"
                            type="text"
                            placeholder="Enter Back Cover Standard"
                        />
                        <MyTextInput
                            label="Back Cover certificates (if applicable)"
                            name="backCoverCertificate"
                            type="text"
                            placeholder="Enter Back Cover certificates (if applicable)"
                        />
                        <MyTextInput
                            label="Encapsulation material"
                            name="encapsulationMaterial"
                            type="text"
                            placeholder="Enter Encapsulation material"
                        />
                        <MyTextInput
                            label="Encapsulation Manufacturer Name"
                            name="encapsulationcoverManf"
                            type="text"
                            placeholder="Enter Encapsulation Manufacturer Name"
                        />

                        <MyTextInput
                            label="Encapsulation Type/Model"
                            name="encapsulationCoverType"
                            type="text"
                            placeholder="Enter Encapsulation Type/Model"
                        />
                        <MyTextInput
                            label="Encapsulation Standard(if applicable)"
                            name="encapsulationCoverStandard"
                            type="text"
                            placeholder="Enter Encapsulation Standard"
                        />
                        <MyTextInput
                            label="Encapsulation certificates (if applicable)"
                            name="encapsulationCoverCertificate"
                            type="text"
                            placeholder="Enter Encapsulation certificates (if applicable)"
                        />
                        <MyTextInput
                            label="Frame Parts Length"
                            name="framepartslen"
                            type="text"
                            placeholder="Enter Frame Parts Length"
                        />
                        <MyTextInput
                            label="Frame Parts width"
                            name="framepartswidth"
                            type="text"
                            placeholder="Enter Frame Parts width"
                        />

                        <MyTextInput
                            label="Frame Parts height"
                            name="framepartsheight"
                            type="text"
                            placeholder="Enter Frame Parts height"
                        />

                        <MyTextInput
                            label="Frame parts material "
                            name="framepartsmaterial"
                            type="text"
                            placeholder="Enter Frame parts material "
                        />

                        <MyTextInput
                            label="Frame parts Manufacturer Name"
                            name="framepartscoverManf"
                            type="text"
                            placeholder="Enter Frame parts Manufacturer Name"
                        />

                        <MyTextInput
                            label="Frame parts Type/Model"
                            name="framepartsCoverType"
                            type="text"
                            placeholder="Enter Frame parts Type/Model"
                        />
                        <MyTextInput
                            label="Frame parts Standard(if applicable)"
                            name="framepartsCoverStandard"
                            type="text"
                            placeholder="Enter Frame parts Standard"
                        />
                        <MyTextInput
                            label="Frame parts certificates (if applicable)"
                            name="framepartsCoverCertificate"
                            type="text"
                            placeholder="Enter Frame parts certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Adhesive (edge-sealing frame) Material"
                            name="adhesive"
                            type="text"
                            placeholder="Enter Adhesive (edge-sealing frame) Material"
                        />
                        <MyTextInput
                            label="Adhesive (edge-sealing frame) Manufacturer Name"
                            name="adhesiveManf"
                            type="text"
                            placeholder="Enter Adhesive (edge-sealing frame) Manufacturer Name"
                        />

                        <MyTextInput
                            label="Adhesive (edge-sealing frame) Type/Model"
                            name="adhesiveType"
                            type="text"
                            placeholder="Enter Adhesive (edge-sealing frame) Type/Model"
                        />
                        <MyTextInput
                            label="Adhesive (edge-sealing frame) Standard(if applicable)"
                            name="adhesiveStandard"
                            type="text"
                            placeholder="Enter Adhesive (edge-sealing frame) Standard"
                        />
                        <MyTextInput
                            label="Adhesive (edge-sealing frame) certificates (if applicable)"
                            name="adhesiveCertificate"
                            type="text"
                            placeholder="Enter Adhesive (edge-sealing frame) certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Solar Cells Length"
                            name="solarCelllen"
                            type="text"
                            placeholder="Enter Solar Cells Length"
                        />
                        <MyTextInput
                            label="Solar Cells width"
                            name="solarCellwidth"
                            type="text"
                            placeholder="Enter Solar Cells width"
                        />

                        <MyTextInput
                            label="Solar Cells height"
                            name="solarCellheight"
                            type="text"
                            placeholder="Enter Solar Cells height"
                        />

                        <MyTextInput
                            label="Solar cell Material"
                            name="solarCellMaterial"
                            type="text"
                            placeholder="Enter Solar cell Material "
                        />
                        <MyTextInput
                            label="Solar cell Manufacturer Name"
                            name="solarCelleManf"
                            type="text"
                            placeholder="Enter Solar cell Manufacturer Name"
                        />

                        <MyTextInput
                            label="Solar cell Type/Model"
                            name="solarCellType"
                            type="text"
                            placeholder="Enter Solar cell Type/Model"
                        />
                        <MyTextInput
                            label="Solar cell Standard(if applicable)"
                            name="solarCellStandard"
                            type="text"
                            placeholder="Enter Solar cell Standard"
                        />
                        <MyTextInput
                            label="Solar cell certificates (if applicable)"
                            name="solarCellCertificate"
                            type="text"
                            placeholder="Enter Solar cell certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Number of solar cells Technical data / ratings"
                            name="noOfSolarCells"
                            type="text"
                            placeholder="Enter Number of solar cells Technical data / ratings"
                        />

                        <MyTextInput
                            label="Number of solar cells Standard(if applicable)"
                            name="noOfsolarCellStandard"
                            type="text"
                            placeholder="Enter Number of solar cells Standard(if applicable)"
                        />
                        <MyTextInput
                            label="Number of solar cells certificates (if applicable)"
                            name="noOfsolarCellCertificate"
                            type="text"
                            placeholder="Enter Number of solar cells certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Cells per bypass diode Technical data / ratings"
                            name="bypassDiode"
                            type="text"
                            placeholder="Enter Cells per bypass diode Technical data / ratings"
                        />
                        <MyTextInput
                            label="Cells per bypass diode Standard(if applicable)"
                            name="cellbypassDiodeStandard"
                            type="text"
                            placeholder="Enter Cells per bypass diode Standard(if applicable)"
                        />
                        <MyTextInput
                            label="Cells per bypass diode certificates (if applicable)"
                            name="cellbypassDiodeCertificate"
                            type="text"
                            placeholder="Enter Cells per bypass diode certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Number of cells in Series"
                            name="serialCell"
                            type="text"
                            placeholder="Enter Number of cells in Series"
                        />

                        <MyTextInput
                            label="Number of cells in Parallel"
                            name="ParallelCell"
                            type="text"
                            placeholder="Enter Number of cells in Parallel"
                        />

                        <MyTextInput
                            label="Serial/parallel connection of cells (S,SP,SPS) Standard(if applicable)"
                            name="serialParallelStandard"
                            type="text"
                            placeholder="Enter Serial/parallel connection of cells (S,SP,SPS) Standard(if applicable)"
                        />
                        <MyTextInput
                            label="Serial/parallel connection of cells (S,SP,SPS) certificates (if applicable)"
                            name="serialParallelCertificate"
                            type="text"
                            placeholder="Enter Serial/parallel connection of cells (S,SP,SPS) certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Bypass diode Rating (A)"
                            name="bypassDiodeRating"
                            type="text"
                            placeholder="Enter Bypass diode Rating (A)"
                        />

                        <MyTextInput
                            label="Bypass diode max. junction temp. (°C)"
                            name="bypassDiodeTemp"
                            type="text"
                            placeholder="Enter Bypass diode max. junction temp. (°C)"
                        />
                        <MyTextInput
                            label="Bypass diode Manufacturer Name"
                            name="bypassDiodeManf"
                            type="text"
                            placeholder="Enter Bypass diode Manufacturer Name"
                        />

                        <MyTextInput
                            label="Bypass diode Type/Model"
                            name="bypassDiodeType"
                            type="text"
                            placeholder="Enter Bypass diode Type/Model"
                        />
                        <MyTextInput
                            label="Bypass diode Standard(if applicable)"
                            name="bypassDiodeStandard"
                            type="text"
                            placeholder="Enter Bypass diode Standard"
                        />
                        <MyTextInput
                            label="Bypass diode certificates (if applicable)"
                            name="bypassDiodeCertificate"
                            type="text"
                            placeholder="Enter Bypass diode certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Number of diodes"
                            name="serialParallel"
                            type="text"
                            placeholder="Enter Number of diodes"
                        />
                        <MyTextInput
                            label="Number of diodes Standard(if applicable)"
                            name="noOfDiodeStandard"
                            type="text"
                            placeholder="Enter Number of diodes Standard"
                        />
                        <MyTextInput
                            label="Number of diodes certificates (if applicable)"
                            name="noOfDiodeCertificate"
                            type="text"
                            placeholder="Enter Number of diodes certificates (if applicable)"
                        />
                        <MyTextInput
                            label="Cell Connector Material"
                            name="cellConnector"
                            type="text"
                            placeholder="Enter Cell Connector Material"
                        />

                        <MyTextInput
                            label="Cell Connector Thickness (mm)"
                            name="cellConnectorThickness"
                            type="text"
                            placeholder="Enter Cell Connector Thickness (mm)"
                        />

                        <MyTextInput
                            label="Cell Connector Width (mm)"
                            name="cellConnectorWidth"
                            type="text"
                            placeholder="Enter Cell Connector Width (mm)"
                        />
                        <MyTextInput
                            label="Cell Connector Manufacturer Name"
                            name="cellConnectorManf"
                            type="text"
                            placeholder="Enter Cell Connector Manufacturer Name"
                        />

                        <MyTextInput
                            label="Cell Connector Type/Model"
                            name="cellConnectorType"
                            type="text"
                            placeholder="Enter Cell Connector Type/Model"
                        />
                        <MyTextInput
                            label="Cell Connector Standard(if applicable)"
                            name="cellConnectorStandard"
                            type="text"
                            placeholder="Enter Cell Connector Standard"
                        />
                        <MyTextInput
                            label="Cell Connector certificates (if applicable)"
                            name="cellConnectorCertificate"
                            type="text"
                            placeholder="Enter Cell Connector certificates (if applicable)"
                        />

                        <MyTextInput
                            label="String connectors Material"
                            name="stringConnector"
                            type="text"
                            placeholder="Enter String connectors Material"
                        />
                        <MyTextInput
                            label="String connectors Thickness (mm)"
                            name="stringConnectorThickness"
                            type="text"
                            placeholder="Enter String connectors Thickness (mm)"
                        />

                        <MyTextInput
                            label="String connectors Width (mm)"
                            name="stringConnectorWidth"
                            type="text"
                            placeholder="Enter String connectors Width (mm)"
                        />
                        <MyTextInput
                            label="String connectors Manufacturer Name"
                            name="stringConnectorManf"
                            type="text"
                            placeholder="Enter String connectors Manufacturer Name"
                        />

                        <MyTextInput
                            label="String connectors Type/Model"
                            name="stringConnectorType"
                            type="text"
                            placeholder="Enter String connectors Type/Model"
                        />
                        <MyTextInput
                            label="String connectors Standard(if applicable)"
                            name="stringConnectorStandard"
                            type="text"
                            placeholder="Enter String connectors Standard"
                        />
                        <MyTextInput
                            label="String connectors certificates (if applicable)"
                            name="stringConnectorCertificate"
                            type="text"
                            placeholder="Enter String connectors certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Soldering material Technical data / ratings"
                            name="solderingMaterial"
                            type="text"
                            placeholder="Enter Soldering material Technical data / ratings"
                        />
                        <MyTextInput
                            label="Soldering material Manufacturer Name"
                            name="solderingMaterialManf"
                            type="text"
                            placeholder="Enter Soldering material Manufacturer Name"
                        />
                        <MyTextInput
                            label="Soldering material Type/Model"
                            name="solderingMaterialType"
                            type="text"
                            placeholder="Enter Soldering material Type/Model"
                        />
                        <MyTextInput
                            label="Soldering material Standard(if applicable)"
                            name="solderingMaterialStandard"
                            type="text"
                            placeholder="Enter Soldering material Standard"
                        />
                        <MyTextInput
                            label="Soldering material certificates (if applicable)"
                            name="solderingMaterialCertificate"
                            type="text"
                            placeholder="Enter Soldering material certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Fluxing agent Technical data / ratings"
                            name="fluxingAgent"
                            type="text"
                            placeholder="Enter Fluxing agent Technical data / ratings"
                        />

                        <MyTextInput
                            label="Fluxing agent Manufacturer Name"
                            name="fluxingAgentManf"
                            type="text"
                            placeholder="Enter Fluxing agent Manufacturer Name"
                        />
                        <MyTextInput
                            label="Fluxing agent Type/Model"
                            name="fluxingAgentType"
                            type="text"
                            placeholder="Enter Fluxing agent Type/Model"
                        />
                        <MyTextInput
                            label="Fluxing agent Standard(if applicable)"
                            name="fluxingAgentStandard"
                            type="text"
                            placeholder="Enter Fluxing agent Standard"
                        />
                        <MyTextInput
                            label="Fluxing agent certificates (if applicable)"
                            name="fluxingAgentCertificate"
                            type="text"
                            placeholder="Enter Fluxing agent certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Cable RTI (°C)"
                            name="cable"
                            type="text"
                            placeholder="Enter Cable RTI (°C)"
                        />

                        <MyTextInput
                            label="Cable Cross-section (mm2)"
                            name="cablearea"
                            type="text"
                            placeholder="Enter Cable Cross-section (mm2)"
                        />

                        <MyTextInput
                            label="Cable Rated voltage (V)"
                            name="cableVoltage"
                            type="text"
                            placeholder="Enter Cable Rated voltage (V)"
                        />

                        <MyTextInput
                            label="Cable Manufacturer Name"
                            name="cableManf"
                            type="text"
                            placeholder="Enter Cable Manufacturer Name"
                        />
                        <MyTextInput
                            label="Cable Type/Model"
                            name="cableType"
                            type="text"
                            placeholder="Enter Cable Type/Model"
                        />
                        <MyTextInput
                            label="Cable Standard(if applicable)"
                            name="cableStandard"
                            type="text"
                            placeholder="Enter Cable Standard"
                        />
                        <MyTextInput
                            label="Cable certificates (if applicable)"
                            name="cableCertificate"
                            type="text"
                            placeholder="Enter Cable certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Connectors Rated Voltage"
                            name="connectedRatedVoltage"
                            type="text"
                            placeholder="Enter Connectors Rated Voltage"
                        />
                        <MyTextInput
                            label="Connectors Rated current (A)"
                            name="connectedRatedCurrent"
                            type="text"
                            placeholder="Enter Connectors Rated current (A)"
                        />

                        <MyTextInput
                            label="Connectors Manufacturer Name"
                            name="connectorManf"
                            type="text"
                            placeholder="Enter Connectors Manufacturer Name"
                        />
                        <MyTextInput
                            label="Connectors Type/Model"
                            name="connectorType"
                            type="text"
                            placeholder="Enter Connectors Type/Model"
                        />
                        <MyTextInput
                            label="Connectors Standard(if applicable)"
                            name="connectorStandard"
                            type="text"
                            placeholder="Enter Connectors Standard"
                        />
                        <MyTextInput
                            label="Connectors certificates (if applicable)"
                            name="connectorCertificate"
                            type="text"
                            placeholder="Enter Connectors certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Junction box Rated Voltage"
                            name="junctionBoxRatedVoltage"
                            type="text"
                            placeholder="Enter Junction box Rated Voltage"
                        />

                        <MyTextInput
                            label="Junction box Rated Current"
                            name="junctionBoxRatedCurrent"
                            type="text"
                            placeholder="Enter Junction box Rated Current"
                        />

                        <MyTextInput
                            label="Junction box RTI (°C)"
                            name="junctionBox"
                            type="text"
                            placeholder="Enter Junction box RTI (°C)"
                        />
                        <MyTextInput
                            label="Junction box Manufacturer Name"
                            name="junctionBoxManf"
                            type="text"
                            placeholder="Enter Junction box Manufacturer Name"
                        />
                        <MyTextInput
                            label="Junction box Type/Model"
                            name="junctionBoxType"
                            type="text"
                            placeholder="Enter Junction box Type/Model"
                        />
                        <MyTextInput
                            label="Junction box Standard(if applicable)"
                            name="junctionBoxStandard"
                            type="text"
                            placeholder="Enter Junction box Standard"
                        />
                        <MyTextInput
                            label="Junction box certificates (if applicable)"
                            name="junctionBoxCertificate"
                            type="text"
                            placeholder="Enter Junction box certificates (if applicable)"
                        />
                        <MyTextInput
                            label="Adhesive (junction box) RTI (°C)"
                            name="junctionBoxadhesive"
                            type="text"
                            placeholder="Enter Adhesive (junction box) RTI (°C)"
                        />
                        <MyTextInput
                            label="Adhesive (junction box) Manufacturer Name"
                            name="junctionBoxadhesiveManf"
                            type="text"
                            placeholder="Enter Adhesive (junction box) Manufacturer Name"
                        />
                        <MyTextInput
                            label="Adhesive (junction box) Type/Model"
                            name="junctionBoxadhesiveType"
                            type="text"
                            placeholder="Enter Adhesive (junction box) Type/Model"
                        />
                        <MyTextInput
                            label="Adhesive (junction box) Standard(if applicable)"
                            name="junctionBoxadhesiveStandard"
                            type="text"
                            placeholder="Enter Adhesive (junction box) Standard"
                        />
                        <MyTextInput
                            label="Adhesive (junction box) certificates (if applicable)"
                            name="junctionBoxadhesiveCertificate"
                            type="text"
                            placeholder="Enter Adhesive (junction box) certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Potting material RTI (°C)"
                            name="pottingMaterial"
                            type="text"
                            placeholder="Enter Potting material RTI (°C)"
                        />
                        <MyTextInput
                            label="Potting material Manufacturer Name"
                            name="pottingMaterialManf"
                            type="text"
                            placeholder="Enter Potting material Manufacturer Name"
                        />
                        <MyTextInput
                            label="Potting material Type/Model"
                            name="pottingMaterialType"
                            type="text"
                            placeholder="Enter Potting material Type/Model"
                        />
                        <MyTextInput
                            label="Potting material Standard(if applicable)"
                            name="pottingMaterialStandard"
                            type="text"
                            placeholder="Enter Potting material Standard"
                        />
                        <MyTextInput
                            label="Potting material certificates (if applicable)"
                            name="pottingMaterialCertificate"
                            type="text"
                            placeholder="Enter Potting material certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Fixing tape for string alignment width(mm)"
                            name="fixingTapeWidth"
                            type="text"
                            placeholder="Enter Fixing tape for string alignment width(mm)"
                        />
                        <MyTextInput
                            label="Fixing tape for string alignment Manufacturer Name"
                            name="fixingTapeManf"
                            type="text"
                            placeholder="Enter Fixing tape for string alignment Manufacturer Name"
                        />
                        <MyTextInput
                            label="Fixing tape for string alignment Type/Model"
                            name="fixingTapeType"
                            type="text"
                            placeholder="Enter Fixing tape for string alignment Type/Model"
                        />
                        <MyTextInput
                            label="Fixing tape for string alignment Standard(if applicable)"
                            name="fixingTapeStandard"
                            type="text"
                            placeholder="Enter Fixing tape for string alignment Standard"
                        />
                        <MyTextInput
                            label="Fixing tape for string alignment certificates (if applicable)"
                            name="fixingTapeCertificate"
                            type="text"
                            placeholder="Enter Fixing tape for string alignment certificates (if applicable)"
                        />

                        <MyTextInput
                            label="Fixing tape for string alignment composite"
                            name="fixingTapeComp"
                            type="text"
                            placeholder="Enter Fixing tape for string alignment composite"
                        />
                        <MyTextInput
                            label="Insulation pad (on connectors) thickness(μm)"
                            name="insulationWidth"
                            type="text"
                            placeholder="Enter Insulation pad (on connectors) thickness(μm)"
                        />

                        <MyTextInput
                            label="Insulation pad (on connectors) composite"
                            name="insulationComposite"
                            type="text"
                            placeholder="Insulation pad (on connectors) composite"
                        />
                        <MyTextInput
                            label="Insulation pad (on connectors) Manufacturer Name"
                            name="insulationManf" Fo
                            type="text"
                            placeholder="Enter Insulation pad (on connectors) Manufacturer Name"
                        />
                        <MyTextInput
                            label="Insulation pad (on connectors) Type/Model"
                            name="insulationType"
                            type="text"
                            placeholder="Enter Insulation pad (on connectors) Type/Model"
                        />
                        <MyTextInput
                            label="Insulation pad (on connectors) Standard(if applicable)"
                            name="insulationStandard"
                            type="text"
                            placeholder="Enter Insulation pad (on connectors) Standard"
                        />
                        <MyTextInput
                            label="Insulation pad (on connectors) certificates (if applicable)"
                            name="insulationCertificate"
                            type="text"
                            placeholder="Enter Insulation pad (on connectors) certificates (if applicable)"
                        />
                        <br/>
                        <button type="submit" className="ui button primary">Submit</button>
                        {message}
                    </Form>
                </Formik>
            </>
        )
    }
}

export default PVmodule;