import React, { Component } from 'react';
import './SDF.css';
import { Redirect } from 'react-router-dom'
// import {NavLink} from "react-router-dom";
// import LoginForm from './components/LoginForm';

class SDFQuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      username: '',
      data: [],
      responseDetail: [
        {
          "responseID": 42,
          "questionID": 6,
          "responseApplicable": true,
          "responseValue": 12,
          "responseComments": "123"
        }
      ]
    };
  }

  componentDidMount() {
    if (this.state.logged_in) {
      this.fetchUser()
      this.fetchQuestions()
      
    }
  }

  fetchUser () {
    fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/core/current_user/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(json => {
          this.setState({ username: json.username });
        })
        // .catch(localStorage.removeItem('token'))
        .catch(error => console.log(error))
  }
  

  fetchQuestions() {
    console.log('fetching questions for standard --> ' + this.props.standard)
    fetch(`https://dashboard.ffutche.org/home/dashboard/dashboard/api/QuestionByStandard/${this.props.standard}`, {
    // fetch(`https://dashboard.ffutche.org/home/dashboard/dashboard/api/QuestionGroup/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.json())
      // .then(this.handleErrors)
      .then(res => {
          //console.log(data) // Prints result from `response.json()` in getRequest
          this.setState({data: res.questiongroups})
      })
      // .then(data => {console.log(data)})
      // .catch(error => console.error(error));
      
  }

  handleErrors(response) {
    if (!response.ok) {
      localStorage.removeItem('token');
    }
    return response;
  }


  pushtoResponse () {
    var responses = {}
    let items = this.state.data
    let i,j
    // console.log(items.length)
    for (i=0;i<items.length;i++){
      // console.log(items[i].questions.length)
      for(j=0;j<items[i].questions.length;j++){
        // this.props.addQuestion(items[i].questions[j].questionID)
        // console.log(JSON.stringify(items[i].questions[j]))
        // console.log(items[i].questions[j].questionID)
        // responses = {...responses,
        //   [items[i].questions[j].questionID]: 
        //       {"responseID": 42,
        //     "questionID": items[i].questions[j].questionID,
        //     "responseApplicable": true,
        //     "responseValue": 12,
        //     "responseComments": "123"}
        //   }
        }
      }
      console.log(JSON.stringify(responses))
      // this.setState({responses: responses})
  }
    
  

  
  render() {
    // this.pushtoResponse()
    if(this.state.username===undefined){
      return <Redirect to='/' />
  }
    let items = this.state.data
  
    console.log("Hello")
    console.log(localStorage.getItem('token'))

  
    let form, body, resdebug

    
    resdebug = this.state.responseDetail.map( response =>
        <tr>
          <td>ID: {response.responseID} questionID: {response.questionID} Applicable: {response.responseApplicable ? 1:0 } Value: {response.responseValue} Comments: {response.responseComments} </td>
        </tr>
      )

      if(items===undefined){
        form = <b>Go to page 1 and select standard and base model</b>
      }else{

      if(items.length>0 && items!==undefined){
        
        body = items.map(item => 
          
          <table border="1" padding="10px">
          <tr  className="sdfQuestionGroup" >
            <td colSpan="3">
              {item.questionGroupNum} - {item.questionGroupTitle}
            </td>
          </tr>
          <tr className="sdfQuestionHeader">
            <td>
              Question
            </td>
            <td>
              Is applicable?
            </td>
            <td>
              Value
            </td>
            <td>
              Comments
            </td>
          </tr>
            {item.questions.map(question => 
            <tr  className="sdfQuestionRow" >
                    <td width="60%">
                      <label for={question.questionGroupID+"-"+question.questionNum}>{question.questionText}</label>
                  </td>
                      <td>
                        {(this.props.resObject[question.questionID]) ? (this.props.resObject[question.questionID].checkbox) ? 
                        (<input type="checkbox" name={question.questionID} onChange={this.props.changeHandler} checked/> ) : 
                        (<input type="checkbox" name={question.questionID} onChange={this.props.changeHandler} /> ) : 
                        (<input type="checkbox" name={question.questionID} onChange={this.props.changeHandler} /> )
                        }
                      </td>
                    <td>
                    {(this.props.resObject[question.questionID]) ? (this.props.resObject[question.questionID].checkbox) ? 
                        (<input type="number" step="0.01" name={question.questionID} value={(this.props.resObject[question.questionID]) ? (this.props.resObject[question.questionID].number) : ''} onChange={this.props.changeHandler} /> ) : 
                        (<input type="number" step="0.01" name={question.questionID} value={(this.props.resObject[question.questionID]) ? (this.props.resObject[question.questionID].number) : ''} onChange={this.props.changeHandler} disabled/> ) : 
                        (<input type="number" step="0.01" name={question.questionID} value={(this.props.resObject[question.questionID]) ? (this.props.resObject[question.questionID].number) : ''} onChange={this.props.changeHandler} disabled/>)
                        }
                      
                    </td>
                    <td>
                    {(this.props.resObject[question.questionID]) ? (this.props.resObject[question.questionID].checkbox) ? 
                        (<input type="text" name={question.questionID} value={(this.props.resObject[question.questionID]) ? (this.props.resObject[question.questionID].text) : ''} onChange={this.props.changeHandler}/> ) : 
                        (<input type="text" name={question.questionID} value={(this.props.resObject[question.questionID]) ? (this.props.resObject[question.questionID].text) : ''} onChange={this.props.changeHandler} disabled/> ) : 
                        (<input type="text" name={question.questionID} value={(this.props.resObject[question.questionID]) ? (this.props.resObject[question.questionID].text) : ''} onChange={this.props.changeHandler} disabled/> )
                        }
                      
                    </td>
                  </tr>
            )}
          </table>
      )
      form = 
          <div>
            <p>Question Sheet</p>
                    <form onSubmit={this.props.handleSubmit}>
            {body}
            <button type="submit">Submit</button></form>
            <b>{this.props.message}</b>
            </div>
      } else {
        form = 
        <div>Unable to fetch</div>
        }
      }
    

    return (
      <div>
          {form}
      </div>
    );
  }
}
export default SDFQuestionForm;
