import React, { Component } from 'react';
import './PvModules.css'

class PvModules extends Component {
  render() {
    return (
      <div className='container'>
        <div className="page_title">
          <h1>PV Modules</h1>
        </div>
          <p>PV Modules page works!</p>
      </div>
    );
  }
}
export default PvModules;
