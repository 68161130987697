import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import './SignIn.css'
import LoginForm from './components/LoginForm';
// import SignupForm from './components/SignupForm';
import Nav from './components/Nav';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      username: ''
    };
  }

  componentDidMount() {
    if (this.state.logged_in) {
      fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/core/current_user/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        // .then(this.handleErrors)
        .then(json => {
          this.setState({ username: json.username });
        })
        // .catch(localStorage.removeItem('token'))
        .catch(error => console.log(error))
    }
  }

  handleErrors(response) {
    if (!response.ok) {
      localStorage.removeItem('token');
    }
    return response;
  }

  handle_login = (e, data) => {
    e.preventDefault();
    fetch('https://dashboard.ffutche.org/token-auth/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      // .then(res => res.json())
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          this.setState({
            logged_in:false
          })
        }
      })
      .then(json => {
        localStorage.setItem('token', json.token);
        this.setState({
          logged_in: true,
          displayed_form: '',
          username: json.user.username
        });
      })
      .catch(error => console.log('error is', error));
  };

  handle_logout = () => {
    localStorage.removeItem('token');
    this.setState({ logged_in: false, username: '' });
  };

  handle_signup = (e, data) => {
    e.preventDefault();
    fetch('https://dashboard.ffutche.org/core/users/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        localStorage.setItem('token', json.token);
        this.setState({
          logged_in: true,
          displayed_form: '',
          username: json.username
        });
      });
  };

  display_form = form => {
    this.setState({
      displayed_form: form
    });
  };

  render() {
    let form;
    if(!this.state.logged_in){
        form = <LoginForm handle_login={this.handle_login} />;
    } else{
      form = null;
    }
    // switch (this.state.displayed_form) {
    //   case 'login':
    //     form = <LoginForm handle_login={this.handle_login} />;
    //     break;
    //   case 'error':
    //     form = <SignupForm  />;
    //     break;
    //   default:
    //     form = null;
    // }

    return (
      <div className="App">
        <Nav
          logged_in={this.state.logged_in}
          display_form={this.display_form}
          handle_logout={this.handle_logout}
        />
        {form}
        <h3>
          {this.state.logged_in
            ? `Hello, ${this.state.username}`
            : 'Please Log In'}
        </h3>
      </div>
    );
  }
  // render() {
  //   return (
  //     <div className="container">
  //       <div className="signin_page_title ">
  //         <h1>Sign In</h1>
  //       </div>
  //         <div className="sign_body">
  //           <form action="index" method="post" autocomplete="off" className="col l12 s12">
  //           <div className="row">
  //             <div className="input-field">
  //               <label for="username" data-error="Username must be 8 characters long">Username:</label>
  //               <input type="text" required  name='username' className="validate" id="username" data-length="8"></input>
  //             </div>
  //           </div>
  //           <div className="row">
  //             <div className="input-field">
  //               <label for="password" data-error="Password must be at least 8 characters long comprising at least a digit, a lowercase letter, and an uppercase letter">Password:</label>
  //               <input type="password" required autocomplete="off" className="validate"></input>
  //             </div>
  //           </div>
  //           <div className="row">
  //             <div className="button-div">
  //               <button className="reg-btn" name="login" type="submit" value="Submit">Done</button>
  //             </div>
  //           </div>
  //           <div className="row">
  //             <NavLink to="#">Forgot Password?</NavLink>
  //           </div>
  //           <div className="row">
  //             <NavLink to="/register">New User Registration</NavLink>
  //           </div>
  //           </form>
  //         </div>
  //     </div>
  //   );
  // }
}
export default SignIn;
