import React, { Component } from 'react';
import './ItSolutions.css';
// import Request from 'superagent';

class ItSolutions extends Component {

  render() {
    return (
      <div className='page'>
        <div className="page_title">
          <h1>IT Solutions</h1>
        </div>
          <p>IT Solutions page works!</p>
      </div>
    );
  }
}
export default ItSolutions;
