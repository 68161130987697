import React, { Component } from 'react';
import './SDF.css';
import { Redirect } from 'react-router-dom'

// import {NavLink} from "react-router-dom";
// import LoginForm from './components/LoginForm';

class SDFRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      username: '',
      data: [],
      message: '',
      cdf: [],
      standard: '',
      baseModel: '',
      newModel: '',
      responseID: ''
    };
    // this.props.changeHandler = this.props.changeHandler.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.username!==undefined) {
      // this.fetchUser()
      // this.fetchQuestions()
      // this.fetchModels()
      Promise.all([
        fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/core/current_user/', {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        }),
        fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/Standard/', {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        }),
        fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/CDF/', {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        })
      ])
      .then(([res1,res2,res3]) => Promise.all([res1.json(),res2.json(),res3.json()]))
      .then(([json1,json2,json3]) => this.setState({
        username: json1.username,
        data: json2,
        cdf: json3
      }))
      .catch(error => console.log(error))

    }
  }

  fetchUser () {
    fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/core/current_user/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(json => {
          this.setState({ username: json.username });
        })
        // .catch(localStorage.removeItem('token'))
        .catch(error => console.log(error))
  }
  

  fetchQuestions() {
    fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/Standard/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.json())
      // .then(this.handleErrors)
      .then(res => {
          //console.log(data) // Prints result from `response.json()` in getRequest
          this.setState({data: res})
      });
      // .then(data => {console.log(data)})
      // .catch(error => console.error(error));
  }

  fetchModels() {
    fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/CDF/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.json())
      // .then(this.handleErrors)
      .then(response => {
          //console.log(data) // Prints result from `response.json()` in getRequest
          this.setState({cdf: response})
      });
      // .then(data => {console.log(data)})
      // .catch(error => console.error(error));
  }

  handleErrors(response) {
    if (!response.ok) {
      localStorage.removeItem('token');
    }
    return response;
  }

  render() {

    if(this.state.username===undefined){
        return <Redirect to='/' />
    }

    let items = this.state.data
    let models = this.state.cdf
    // let message = "HelloWorld"
    let form,body,modelList

    body = items.map(item => 
      <option value={item.standardID}>{item.standardID} - {item.standardName}</option>
      )

    modelList = 
          models.map( model =>
        <option value={model.modelNumber}>{model.modelNumber}</option>
      )
    
    
  form = <div><b>Enter Details:</b>
  <form onSubmit={this.props.handleSubmit}>
      <br></br><label>Select Standard: <select name="standard" value={this.props.standard_val} onChange={this.props.changeHandler}><option />{body}</select></label>
      
      <br></br><label>Select Base Model: <select name="baseModel" value={this.props.baseModel_val} onChange={this.props.changeHandler}><option />{modelList}</select></label>
    
      <br></br><label>Select New Model:<select name="newModel"  value={this.props.newModel_val} onChange={this.props.changeHandler}><option />{modelList}</select></label>
      
      <input type="Submit" value="Submit"/>
    </form>
    </div>
    return (
      <div>
          {form}
          {this.state.message}
      </div>
      
    );
  }
}
export default SDFRegistration;
