import React, { Component } from "react";
// import Input from "./components/Input";
// import ReactDOM from 'react-dom';
import SolarForm from './components/forms/SolarForm'
// import FormExampleForm from './components/forms/FormExampleForm'

class CDFformSolarAssemblies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product : this.props.productKey,
            modelNumber: "",
            lengthMod : "",
            width: "",
            height:"",
            productArea:"",
            softwareVersion :"",
            clampLoad:"",
            slipFactor:"",
            stressArea:"",
            downward:"",
            up:"",
            downslope:"",
            size:"",
            orientation:"landscape",
            number:"",
            manualVersion:"",
            otherspecifications:"",
            selectedOption : 'existing',
            selectedValue:"installationsite",
            logged_in: localStorage.getItem('token') ? true : false,
            username:this.props.username,
            salutation: this.props.salutation,
            first_name: this.props.first_name,
            last_name: this.props.last_name,
            middle_name:this.props.middle_name,
            email : this.props.email,
            officephone :this.props.officephone,
            cellPhone: this.props.cellPhone,
            modelSearchNumber : "",
            updated_by : this.props.username,
        };
    };

    change =  e => {
        this.setState({
            [e.target.name] :  e.target.value
        });
    }


    handleRadioAction = e => {
        if(e.target.value === "new"){ // if existing then make API call and set Values to state which will re-render the component and auto populate the textbox value
            this.ClearForm(e);
        } else {
            this.setState({
                selectedOption: e.target.value
            })
        }

    }

    handlesubmit = (e) =>{
        e.preventDefault();
        console.log(this.state);
        fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/CDF/', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization' : `JWT ${localStorage.getItem('token')}`
            },
            body : JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data) // Prints result from `response.json()` in getRequest
            })
            .catch(error => console.error(error))
    }

    renderModelSearch(){
        return <form className="ui form">
                <div className="twelve wide required field">
                    <label>Enter Base Model Number</label>
                    <input type="text"
                           value={this.state.modelSearchNumber}
                           name="modelSearchNumber"
                           onChange={e => {
                               this.change(e)
                           }}/>
                </div>
                <button  class="positive ui button" onClick={e => this.handleModelSearch(e)}>Search</button>
            </form>
    }

    handleModelSearch = (e) => {
        e.preventDefault();
        let url = 'https://dashboard.ffutche.org/home/dashboard/dashboard/api/CDF/' + this.state.modelSearchNumber;
        console.log('URL ->', url);
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization' : `JWT ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({
                    modelNumber : "",
                    selectedOption : "",
                    lengthMod : data.lengthMod,
                    width: data.width,
                    height:data.height,
                    productArea: data.productArea,
                    softwareVersion :data.softwareVersion,
                    clampLoad:data.clampLoad,
                    slipFactor:data.slipFactor,
                    stressArea:data.stressArea,
                    downward:data.downward,
                    up:data.up,
                    downslope:data.downslope,
                    size:data.size,
                    orientation:data.orientation,
                    number:data.number,
                    manualVersion:data.manualVersion,
                    otherspecifications:data.otherspecifications,
                    selectedValue:data.selectedValue,
                    logged_in: localStorage.getItem('token') ? true : false,
                    username: ''
                    })
                })
                .catch(error => console.error(error));

        }


    ClearForm = e => {
        this.setState({
            modelNumber: "",
            lengthMod : "",
            width: "",
            height:"",
            productArea:"",
            softwareVersion :"",
            clampLoad:"",
            slipFactor:"",
            stressArea:"",
            downward:"",
            up:"",
            downslope:"",
            size:"",
            orientation:"landscape",
            number:"",
            manualVersion:"",
            otherspecifications:"",
            selectedOption : 'new',
            selectedValue:"installationsite",
            logged_in: localStorage.getItem('token') ? true : false,
            username: ''
        });
    }

    render() {
        const fullName = this.props.salutation +  ' ' + this.props.first_name + ' ' + this.props.middle_name + ' ' + this.props.last_name;
        const email =  this.props.email
        const cellPhone = this.props.cellPhone
        const officePhone = this.props.officephone
        let formComponent;
        if (this.state.selectedOption === 'existing'){
            formComponent = this.renderModelSearch();
        } else {
            formComponent = <SolarForm productKey={this.state.product} initialValues={this.state}/>;
        }
        return (
            <div className="ui segment">
                <div className="ui form">
                    <div className="grouped twelve wide required fields">
                        <label>Product Information:</label>
                        <div className="twelve wide field radio-button-padding">
                            <div className="ui radio">
                                <input type="radio"
                                       value="new"
                                       id="new"
                                       checked={this.state.selectedOption !== 'existing'}
                                       onChange={this.handleRadioAction}/>
                                <label>The design being submitted is not based on previously certified model.</label>
                            </div>
                        </div>
                        <div className="twelve wide field radio-button-padding">
                            <div className="ui radio">
                                <input type="radio"
                                       value="existing"
                                       id="existing"
                                       checked={this.state.selectedOption === 'existing'}
                                       onChange={this.handleRadioAction}/>
                                <label>The design being submitted is based on previously certified based model.</label>
                            </div>
                        </div>
                    </div>
                    <div className="eight wide field"><label>Client Name : </label>
                        {fullName}
                    </div>
                    <div className="eight wide field"><label>Client Email Address : </label>
                        {email} </div>
                    <div className="eight wide field"><label>Client Cell Phone Number : </label>
                        {cellPhone} </div>
                    <div className="eight wide field"><label>Client Office Phone Number : </label>
                        {officePhone} </div>
                    {formComponent}

                </div>
            </div>
        );
    }
}


export default CDFformSolarAssemblies;
