import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import './GoogleMaps.css';

export class MapContainer extends Component {

  render() {

    const style = {
                  width: '25.9%',
                  height: '20em',
                  margin: '0'
                  }

    return (
      <div className="container">
        <div className="map_container">
          <Map  google={this.props.google}
                style={style}
                initialCenter={{
                lat: 33.396465602916635,
                lng: -111.95373594760896
                }}
                zoom={15}
                >

          <Marker
            title={'SolarPTL'}
            name={'SolarPTL'}
            position={{
            lat: 33.396465602916635,
            lng: -111.95373594760896
            }}/>

          </Map>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyD5L1hvw87-tAj6OZWYEYrAD7AG3hoLrMQ'),
})(MapContainer)
