import React, { Component } from "react";
import CDFformPV from './CDFformPV';
import CDFformSolarAssemblies from './CDFformSolarAssemblies';
import {Redirect} from 'react-router-dom'

class CDFform extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProduct : "",
            products :[],
            logged_in: localStorage.getItem('token') ? true : false,
            username: this.props.username,
            salutation: this.props.salutation,
            first_name: this.props.first_name,
            last_name: this.props.last_name,
            middle_name:this.props.middle_name,
            email : this.props.email,
            officephone :this.props.officephone,
            cellPhone: this.props.cellPhone,
        }
    };

    componentDidMount(){
        fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/Product/', {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization' : `JWT ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    products : data,
                    selectedProduct: data[0].ProductName
                })
            })
            .catch(error => console.error(error));
    }


    handleProductChange(e) {
        console.log('changing state, ', e.target.value)
        this.setState({ selectedProduct: e.target.value });
    }

    render(){


        let dropdownArray = [];
        const productSolar = 1;
        const productPV = 2;
        const productRack = 3;
        this.state.products.forEach(value => {
            dropdownArray.push(<option value={value.ProductName}>{value.ProductName}</option>);
        });
        let component = <CDFformSolarAssemblies username={this.state.username}
                                                first_name={this.props.first_name}
                                                last_name={this.props.last_name}
                                                email ={this.props.email}
                                                salutation = {this.state.salutation}
                                                middle_name= {this.state.middle_name}
                                                officephone = {this.state.officephone}
                                                cellPhone= {this.state.cellPhone}
                                                productKey={productSolar}/>;
        if (this.state.selectedProduct === "PV Modules") {

            component = <CDFformPV username={this.state.username}
                                   first_name={this.props.first_name}
                                   last_name={this.props.last_name}
                                   email ={this.props.email}
                                   salutation = {this.state.salutation}
                                   middle_name= {this.state.middle_name}
                                   officephone = {this.state.officephone}
                                   cellPhone= {this.state.cellPhone}
                                   productKey={productPV}/>;
        } else if (this.state.selectedProduct === "Solar Assemblies"){
            component = <CDFformSolarAssemblies username={this.state.username}
                                                first_name={this.props.first_name}
                                                last_name={this.props.last_name}
                                                email ={this.props.email}
                                                salutation = {this.state.salutation}
                                                middle_name= {this.state.middle_name}
                                                officephone = {this.state.officephone}
                                                cellPhone= {this.state.cellPhone}
                                                productKey={productSolar}/>;
        } else if (this.state.selectedProduct === "Rack and Trackers"){
            component = <CDFformSolarAssemblies username={this.state.username}
                                                first_name={this.props.first_name}
                                                last_name={this.props.last_name}
                                                email ={this.props.email}
                                                salutation = {this.state.salutation}
                                                middle_name= {this.state.middle_name}
                                                officephone = {this.state.officephone}
                                                cellPhone= {this.state.cellPhone}
                                                productKey={productRack}/>;
        }



        if (!this.state.username === undefined) {
           return <Redirect to = '/' />
        } else {
            return (

                <div className="ui segment">
                <h2 class="ui blue header">
                Constructional Data Form
                </h2>
                <div className="twelve wide required field">
                <div>Product</div>
                <select className="ui selection dropdown" onChange={e => this.handleProductChange(e)}>
                {dropdownArray}
                </select>
                </div>
                {component}
                </div>
            );
        }



    }
}

export default CDFform;
