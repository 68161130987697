import React, { Component } from 'react';
import './RacksTrackers.css'

class RacksTrackers extends Component {
  render() {
    return (
      <div className='container'>
        <div className="page_title">
          <h1>Racks & Trackers</h1>
        </div>
          <p>RacksTrackers page works!</p>
      </div>
    );
  }
}
export default RacksTrackers;
