import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import {createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
//import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'

import Main from './Main';



import reducer from './store/reducers/auth'

const composeEnchances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnchances(
  applyMiddleware(thunk)
  ));

const app = (
  <Provider store={store}>
    <Main />
  </Provider>
)

ReactDOM.render(
  app,
  document.getElementById("root")
);

//--> ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

//--> serviceWorker.unregister();
