import React, { Component } from 'react';
import './PvSystems.css'

class PvSystems extends Component {
  render() {
    return (
      <div className='container'>
        <div className="page_title">
          <h1>PV Systems & BOS</h1>
        </div>
          <p>PV Systems page works!</p>
      </div>
    );
  }
}
export default PvSystems;
