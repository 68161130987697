import React, { Component } from 'react';
import Slider from "react-slick";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "./SlideShow.css"

// more information on this react carousel library can be found at: https://react-slick.neostack.com

class SlideShow extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      fade: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="container">
        <Slider {...settings}>
          <div className="image">
            <img alt='test img 1'src={"../assets/ptl.png"} />
          </div>
          <div className="image">
            <img alt='test img 2' src={"../assets/oldmen.png"} />
          </div>
          <div className="image">
            <img alt='test img 3' src="../assets/timeline_PTL.png" />
          </div>
        </Slider>
      </div>
    );
  }
}

export default SlideShow;
