import React, { Component } from "react";
import input from "./components/Input";
import './CDFformPV.css';
import PVmodule from './components/forms/PVmodule'

class CDFformPV extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modelNumber: "",
            product:this.props.productKey,
            lengthMod : "",
            width: "",
            height:"",
            productArea:"",
            moduleTech:"",
            numberofCells:"",
            appClass:"",
            fireProtectionClass:"",
            note:"",
            frontCoverprocess:"",
            frontCoverthickness:"",
            backCoverThickness:"",
            backCover:"",
            encapsulationMaterial:"",
            framepartsmaterial:"",
            framepartsheight: "",
            framepartswidth:"",
            framepartslen:"",
            ParallelCell:"",
            adhesive:"",
            solarCellMaterial:"",
            solarCelllen:"",
            solarCellwidth:"",
            solarCellheight:"",
            noOfSolarCells:"",
            bypassDiode:"",
            serialCell:"",
            cellConnector:"",
            cellConnectorWidth:"",
            cellConnectorThickness:"",
            stringConnector:"",
            stringConnectorWidth:"",
            stringConnectorThickness:"",
            solderingMaterial:"",
            fluxingAgent:"",
            cable:"",
            cableVoltage:"",
            cablearea:"",
            connectedRatedCurrent:"",
            connectedRatedVoltage:"",
            junctionBoxRatedVoltage:"",
            junctionBoxRatedCurrent:"",
            junctionBox:"",
            junctionBoxadhesive:"",
            pottingMaterial:"",
            fixingTapeComp:"",
            fixingTapeWidth:"",
            insulationComposite:"",
            insulationWidth:"",
            selectedOption : 'existing',
            logged_in: localStorage.getItem('token') ? true : false,
            salutation: this.props.salutation,
            username: this.props.username,
            first_name : this.props.first_name,
            last_name: this.props.last_name,
            middle_name:this.props.middle_name,
            officephone :this.props.officephone,
            cellPhone: this.props.cellPhone,
            updated_by : this.props.username,
            modelSearchNumber : ""
        };
    };

    handleRadioAction = e => {
        if(e.target.value === "new"){
            this.ClearForm(e);
        } else {
            this.setState({
                selectedOption: e.target.value
            })
        }
    }

    change =  e => {
        this.setState({
            [e.target.name] :  e.target.value
        });
    }

    renderModelSearch(){
        return <form className="ui form">
            <div className="twelve wide required field">
                <label>Enter Base Model Number</label>
                <input type="text"
                       value={this.state.modelSearchNumber}
                       name="modelSearchNumber"
                       onChange={e => {
                           this.change(e)
                       }}/>
            </div>
            <button  class="positive ui button" onClick={e => this.handleModelSearch(e)}>Search</button>
        </form>
    }

    handlesubmit = (e) =>{
        e.preventDefault();
        console.log(this.state);
        alert('Your information has been submitted successfully');

        fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/CDF/', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization' : `JWT ${localStorage.getItem('token')}`
            },
            body : JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
            })
            .catch(error => console.error(error))
    }

    handleModelSearch = (e) => {
        e.preventDefault();
        let url = 'https://dashboard.ffutche.org/home/dashboard/dashboard/api/CDF/' + this.state.modelSearchNumber;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization' : `JWT ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    modelNumber : "",
                    selectedOption : "",
                    lengthMod : data.lengthMod,
                    width: data.width,
                    height:data.height,
                    productArea: data.productArea,
                    moduleTech:data.moduleTech,
                    numberofCells:data.numberofCells,
                    appClass:data.appClass,
                    fireProtectionClass:data.fireProtectionClass,
                    note:data.note,
                    frontCoverprocess:data.frontCoverprocess,
                    frontCoverthickness:data.frontCoverthickness,
                    backCoverThickness:data.backCoverThickness,
                    backCover:data.backCover,
                    encapsulationMaterial:data.encapsulationMaterial,
                    framepartsmaterial:data.framepartsmaterial,
                    framepartsheight: data.framepartsheight,
                    framepartswidth:data.framepartswidth,
                    framepartslen:data.framepartslen,
                    ParallelCell:data.ParallelCell,
                    adhesive:data.adhesive,
                    solarCellMaterial:data.solarCellMaterial,
                    solarCelllen:data.solarCelllen,
                    solarCellwidth:data.solarCellwidth,
                    solarCellheight:data.solarCellheight,
                    noOfSolarCells:data.noOfSolarCells,
                    bypassDiode:data.bypassDiode,
                    serialCell:data.serialCell,
                    cellConnector:data.cellConnector,
                    cellConnectorWidth:data.cellConnectorWidth,
                    cellConnectorThickness:data.cellConnectorThickness,
                    stringConnector:data.stringConnector,
                    stringConnectorWidth:data.stringConnectorWidth,
                    stringConnectorThickness:data.stringConnectorThickness,
                    solderingMaterial:data.solderingMaterial,
                    fluxingAgent:data.fluxingAgent,
                    cable:data.cable,
                    cableVoltage:data.cableVoltage,
                    cablearea:data.cablearea,
                    connectedRatedCurrent:data.connectedRatedCurrent,
                    connectedRatedVoltage:data.connectedRatedVoltage,
                    junctionBoxRatedVoltage:data.junctionBoxRatedVoltage,
                    junctionBoxRatedCurrent:data.junctionBoxRatedCurrent,
                    junctionBox:data.junctionBox,
                    junctionBoxadhesive:data.junctionBoxadhesive,
                    pottingMaterial:data.pottingMaterial,
                    fixingTapeComp:data.fixingTapeComp,
                    fixingTapeWidth:data.fixingTapeWidth,
                    insulationComposite:data.insulationComposite,
                    insulationWidth:data.insulationWidth,
                    logged_in: localStorage.getItem('token') ? true : false,
                    username: ""
                })
            })
            .catch(error => {
                console.error(error)
            });
    }

    ClearForm = e => {
        // e.preventDefault();
        this.setState ({
            modelNumber: "",
            lengthMod : "",
            width: "",
            height:"",
            productArea:"",
            moduleTech:"",
            numberofCells:"",
            appClass:"",
            fireProtectionClass:"",
            note:"",
            frontCoverprocess:"",
            frontCoverthickness:"",
            backCoverThickness:"",
            backCover:"",
            encapsulationMaterial:"",
            framepartsmaterial:"",
            framepartsheight: "",
            framepartswidth:"",
            framepartslen:"",
            ParallelCell:"",
            adhesive:"",
            solarCellMaterial:"",
            solarCelllen:"",
            solarCellwidth:"",
            solarCellheight:"",
            noOfSolarCells:"",
            bypassDiode:"",
            serialCell:"",
            cellConnector:"",
            cellConnectorWidth:"",
            cellConnectorThickness:"",
            stringConnector:"",
            stringConnectorWidth:"",
            stringConnectorThickness:"",
            solderingMaterial:"",
            fluxingAgent:"",
            cable:"",
            cableVoltage:"",
            cablearea:"",
            connectedRatedCurrent:"",
            connectedRatedVoltage:"",
            junctionBoxRatedVoltage:"",
            junctionBoxRatedCurrent:"",
            junctionBox:"",
            junctionBoxadhesive:"",
            pottingMaterial:"",
            fixingTapeComp:"",
            fixingTapeWidth:"",
            insulationComposite:"",
            insulationWidth:"",
            selectedOption : 'new',
            logged_in: localStorage.getItem('token') ? true : false,
            username: ""

        });
    }

    render() {
        const fullName = this.props.salutation +  ' ' + this.props.first_name + ' ' + this.props.middle_name + ' ' + this.props.last_name;
        const email =  this.props.email
        const cellPhone = this.props.cellPhone
        const officePhone = this.props.officephone
        let formComponent;
        if (this.state.selectedOption === 'existing'){
            formComponent = this.renderModelSearch();
        } else {
            formComponent = <PVmodule productKey={this.state.product} initialValues={this.state}/>;
        }

        return (
            <div className="ui segment">
                <div className="ui form">
                    <div className="grouped twelve wide required fields">
                        <label>Product Information:</label>
                        <div className="twelve wide field radio-button-padding">
                            <div className="ui radio">
                                <input type="radio"
                                       value="new"
                                       id="new"
                                       checked={this.state.selectedOption !== 'existing'}
                                       onChange={this.handleRadioAction}/>
                                <label>The design being submitted is not based on previously certified model.</label>
                            </div>
                        </div>
                        <div className="twelve wide field radio-button-padding">
                            <div className="ui radio">
                                <input type="radio"
                                       value="existing"
                                       id="existing"
                                       checked={this.state.selectedOption === 'existing'}
                                       onChange={this.handleRadioAction}/>
                                <label>The design being submitted is based on previously certified based model.</label>
                            </div>
                        </div>
                    </div>
                    <div className="eight wide field"><label>Client Name : </label>
                        {fullName}
                    </div>
                    <div className="eight wide field"><label>Client Email Address : </label>
                        {email} </div>
                    <div className="eight wide field"><label>Client Cell Phone Number : </label>
                        {cellPhone} </div>
                    <div className="eight wide field"><label>Client Office Phone Number : </label>
                        {officePhone} </div>
                {formComponent}
                </div>
            </div>
        );
    }
}

export default CDFformPV;
