import React, { Component } from 'react';
// import Certs from './components/certificates';
import FilterPanelApp from './components/filterpanel.js'
//import Contacts from './components/contacts';
import './Certificates.css';
import axios from 'axios'

// const { useState } = React

// export var certData = []


class Certificates extends Component {


  // state = {    certs: [],  }

  state = {
    word: '',
    certs: [],
  }

  handleChange = event => {
    this.setState({ word: event.target.value });

  }

  handleSubmit = event => {
    event.preventDefault()

    // const keyword = { word: this.state.word }


     axios.get(`http://solar.ffutche.org/api/certificates/?search=${this.state.word}`)
       .then(res => {
         console.log(res);
         console.log(res.data);
         this.setState({ certs: res.data });
       })


   }
  // certs = 'http://127.0.0.1:8000/api/certificate/'
  // test = 'http://jsonplaceholder.typicode.com/users'

/*
  componentDidMount() {
    //fetch('http://jsonplaceholder.typicode.com/users')
    fetch('http://127.0.0.1:8000/api/certificate/')
    .then(res => res.json())
    .then((data) => {
      this.setState({ certs: data })
    })
    .catch(console.log)
  }
*/

      //(res => {const persons = res.data; this.setState({ persons }); })



  render() {
    return (
      <div className='page'>
        <div className="page_title">
          <h1>Certificates</h1>
        </div>
          <form onSubmit={this.handleSubmit}>
                <input
                  type="text"
                  // value={this.keyword}
                  onChange={this.handleChange}
                  placeholder="Search"

                />
                <button type="submit">Submit</button>
              </form>
          {/*/ <Certs certs={this.state.certs} />*/}
          <FilterPanelApp certData={this.state.certs}/>
      </div>
    );
  }
}

export default Certificates;
