import React, { Component } from 'react';
import { Route, HashRouter } from 'react-router-dom';
import './Main.css';
import Nav from './Nav';
import Home from './Home';
import SignIn from './SignIn';
import Register from './Register';
import Services from './Services';
import Certificates from './Certificates';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import PvModules from './PvModules';
import PvSystems from './PvSystems';
import RacksTrackers from './RacksTrackers';
import ItSolutions from './ItSolutions';
import Dashboard from './Dashboard';
import CDF from './CDF';
// import UserRegister from './UserRegister';
// import ProductRegistration from './components/ProductRegistration'
import Foot from './Foot';
// import TestForm from './components/forms/FormExampleForm'

import SDFRegistration from './SDFRegistration';
import SDF from './SDF';
import SDFResults from './SDFResults';
import SDFQuestionForm from './SDFQuestionForm';

function refreshPage() {
  window.location.reload(false);
}


class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      username: '',
      salutation: '',
      first_name : '',
      middle_name:'',
      last_name : '',
      officephone :'',
      cellPhone: '',
      email:'',


    };
  }

  componentDidMount() {
    if (this.state.logged_in) {
      fetch('https://dashboard.ffutche.org/core/current_user/', {

        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        // .then(this.handleErrors)
        .then(json => {
          this.setState({ username: json.username,
                        first_name: json.first_name,
              last_name: json.last_name,
              email:json.email,
              salutation: json.salutation,
              middle_name:json.middle_name,
              officephone :json.officephone,
              cellPhone: json.cellPhone
                        });
        })
        // .catch(localStorage.removeItem('token'))
        .catch(error => console.log(error))
    }
  }

  handleErrors(response) {
    if (!response.ok) {
      localStorage.removeItem('token');
    }
    return response;
  }

  handle_login = (e, data) => {
    e.preventDefault();
    fetch('https://dashboard.ffutche.org/token-auth/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      // .then(res => res.json())
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          this.setState({
            logged_in:false
          })
        }
      })
      .then(json => {
        localStorage.setItem('token', json.token);
        this.setState({
          logged_in: true,
          displayed_form: '',
          username: json.user.username,
          first_name: json.user.first_name,
          last_name: json.user.last_name,
          email :json.user.email,
          salutation: json.salutation,
          middle_name:json.middle_name,
          officephone :json.officephone,
          cellPhone: json.cellPhone,
        });
      })
      .catch(error => console.log('error is', error));
      // refreshPage();
  };

  handle_logout = () => {
    localStorage.removeItem('token');
    this.setState({ logged_in: false, username: '', salutation:'',first_name:'', middle_name:'', last_name: '' ,email:'',officephone:'', cellPhone:''});
    refreshPage();
  };

  handle_signup = (e, data) => {
    e.preventDefault();
    fetch('https://dashboard.ffutche.org/core/users/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        localStorage.setItem('token', json.token);
        this.setState({
          logged_in: true,
          displayed_form: '',
          username: json.username,
          first_name: json.first_name,
          last_name: json.last_name,
          email: json.email,
          salutation: json.salutation,
          middle_name:json.middle_name,
          officephone :json.officephone,
          cellPhone: json.cellPhone,
        });
      });
  };

  

  display_form = form => {
    this.setState({
      displayed_form: form
    });
  };
  render() {
    return (
      <HashRouter>
        <div className="container">
          <header>
            <Nav />
          </header>
            <div className="main_content">
              <Route exact path="/" component={Home}/>
              <Route path="/sign-in" component={SignIn}/>
              <Route path="/register" component={Register}/>
              <Route path="/services" component={Services}/>
              <Route path="/certificates" component={Certificates}/>
              <Route path="/about-us" component={AboutUs}/>
              <Route path="/contact-us" component={ContactUs}/>
              <Route path="/pv-modules" component={PvModules}/>
              <Route path="/pv-systems" component={PvSystems}/>
              <Route path="/racks-trackers" component={RacksTrackers}/>
              <Route path="/it-solutions" component={ItSolutions}/>
              <Route path="/sdfRegistration" component={SDFRegistration} />
              <Route path="/sdfResults" component={SDFResults} />
              <Route path="/sdf" component={SDF} />
              <Route path="/sdfQuestionForm" component={SDFQuestionForm} />
              <Route path="/dashboard" render={(props) => <Dashboard {...props}
                                                               username={this.state.username}
                                                               first_name={this.state.first_name}
                                                               last_name={this.state.last_name}
                                                               email ={this.state.email}
                                                               salutation = {this.state.salutation}
                                                               middle_name= {this.state.middle_name}
                                                               officephone = {this.state.officephone}
                                                               cellPhone= {this.state.cellPhone}
                  /> }
              />
              {/*<Route path="/userRegister" component={UserRegister} />*/}
                {/*<Route path="/productregistration" component={ProductRegistration} />*/}
              <Route path="/cdf"
                     render={(props) => <CDF {...props}
                                             username={this.state.username}
                                             first_name={this.state.first_name}
                                             last_name={this.state.last_name}
                                             email ={this.state.email}
                                             salutation = {this.state.salutation}
                                             middle_name= {this.state.middle_name}
                                             officephone = {this.state.officephone}
                                             cellPhone= {this.state.cellPhone}
                     />}
              />
            </div>
          <footer>
            <Foot />
          </footer>
        </div>
      </HashRouter>
    );
  }
}

export default (Main);
