import React, { Component } from 'react';
import './AboutUs.css'

class AboutUs extends Component {
  render() {
    return (
      <div className='page'>
        <div className="page_title">
          <h1>About Us</h1>
        </div>
          <p>About Us page works!</p>
      </div>
    );
  }
}
export default AboutUs;
