import React, { Component } from 'react';
import SlideShow from './SlideShow';
import './Home.css';

class Home extends Component {
  render() {
    return (
        <div className="page">
          <div className='slide_bg'>
            <div className="slide_container">
              <SlideShow />
            </div>
          </div>
          <div className="home_main">

            <h1>Welcome to the SolarPTL website</h1>

            <p>Like any growing technology, the growth of the solar photovoltaic (PV) technology has come with some risks. More and more people are investing into PV, either as manufacturers, distributors, developers or consumers. And even more are waiting to jump in. But at the same time, the risk of losing confidence could become greater, because of poor quality. The challenge, therefore, is to sustain the growth and convince the most skeptic public. &nbsp;The PV design qualification and type certification are one way to address that challenge. As defined in the IEC61215 standard document, the purpose “is to determine the electrical and thermal characteristics of the module and to show, as far as is possible within reasonable constraints of cost and time, that the module is capable of withstanding prolonged exposure in general open-air climates”.</p>

            <p>Our facilities include environmental test chambers that subject test modules to extreme temperatures and humidity that simulate accelerated aging of components used in module construction.&nbsp; Measurements of module current vs. voltage characteristics are normally obtained using an indoor solar simulator and an outdoor&nbsp; I-V curve tracer with unobstructed sun as a light source.&nbsp; Additionally, long-term outdoor exposure testing services in desert environments and specific engineering testing services related to flat-plate and concentrator photovoltaic modules are also provided.</p>

            <p>The Solar PTL is a world-renowned independent PV testing lab that provides, among other services:</p>


            <div className="list">
                <ul>
                    <li>PV design qualification and type certification service per IEC 61215 standard series and IEC 62108 standard</li>
                    <li>PV safety testing services per UL 1703 and IEC 61730 standards.</li>
                    <li>Racking and tracking system testing</li>
                    <li>PV Performance testing and power rating</li>
                    <li>PV components testing</li>
                    <li>PV systems monitoring and inspections</li>
                    <li>Factory inspections </li>
                </ul>

            </div>
          </div>
        </div>

    );
  }
}

export default Home;
