import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import {  IoIosLogIn } from 'react-icons/io';
// import { IoIosPersonAdd, IoIosLogIn } from 'react-icons/io';
import './Nav.css';
import LoginForm from './components/LoginForm';

function refreshPage() {
  window.location.reload(false);
}

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      username: ''
    };
  }

  componentDidMount() {
    // if (this.state.logged_in) {
    if (this.state.username !== undefined){

      fetch('https://dashboard.ffutche.org/core/current_user/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        // .then(this.handleErrors)
        .then(json => {
          this.setState({ username: json.username });
        })
        // .catch(localStorage.removeItem('token'))
        .catch(error => console.log(error))
    }
  }


  handle_login = (e, data) => {
    e.preventDefault();
    fetch('https://dashboard.ffutche.org/token-auth/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      // .then(res => res.json())
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          this.setState({
            logged_in:false
          });
          alert('Invalid Credentials... Try Again...');
          refreshPage();
        }
      })
      .then(json => {
        localStorage.setItem('token', json.token);
        this.setState({
          logged_in: true,
          displayed_form: '',
          username: json.user.username
        });
        refreshPage();
      })
      .catch(error => console.log('error is', error))
      // .catch(alert('Invalid Credentials... Try Again...'))
      // .catch(refreshPage());
  };

  handle_logout = () => {
    localStorage.removeItem('token');
    this.setState({ logged_in: false, username: '' });
    refreshPage();
  };

  handle_signup = (e, data) => {
    e.preventDefault();
    fetch('https://dashboard.ffutche.org/core/users/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        localStorage.setItem('token', json.token);
        this.setState({
          logged_in: true,
          displayed_form: '',
          username: json.username
        });
      });
  };

  display_form = form => {
    this.setState({
      displayed_form: form
    });
  };

  render() {
    let form;
    let menu;
    // if(!this.state.logged_in){
      if (this.state.username === undefined){
      form = <li><LoginForm handle_login={this.handle_login} /></li>
      menu = <ul>
              <li><NavLink to="/">Home</NavLink></li>
              <li><NavLink to="/services">Services</NavLink></li>
              <li><NavLink to="/certificates">Certificates</NavLink></li>
              <li><NavLink to="/about-us">About Us</NavLink></li>
              <li><NavLink to="/contact-us">Contact Us</NavLink></li>
            </ul>
    } else{
      form = <div className="form-inline"><div>{(this.state.username !== undefined)
        ? `Hello, ${this.state.username}`
        : ''}</div><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<NavLink to="/" onClick={this.handle_logout}>Logout</NavLink></div></div>
      menu = <ul>
        <li><NavLink to="/">Home</NavLink></li>
        <li><NavLink to="/services">Services</NavLink></li>
        <li><NavLink to="/certificates">Certificates</NavLink></li>
        <li><NavLink to="/dashboard">Dashboard</NavLink></li>
        <li><NavLink to="/about-us">About Us</NavLink></li>
        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
      </ul>
    }

  


    return (
      <div className="nav_container">
        <div className="header_block">
          <div className="header_main">
              <div className="logo">
                  <img alt='SolarPTL' src={"../assets/solarptl.png"} />
              </div>
            <div className="header_top">
              <div className="header_top_bar">
                <div className="top_nav_side">
                  <nav>
                    <div className="top_nav">
                        <ul>
                          {/* <li><NavLink to="/register"><IoIosPersonAdd/> Register</NavLink></li> */}
                          {form}
                        </ul>
                    </div>
                  </nav>
                </div>
                <div className="search_eles">
                  <form>
                      <div className="search_cont_a">
                        <input className="search_field" type="text" placeholder="Search..." ></input>
                        </div>
                      <div className="search_cont_b">
                      <button className="search_button"><svg width="12" height="12" viewBox="0 0 12 12"><title>search</title><path d="m4.8495 7.8226c0.82666 0 1.5262-0.29146 2.0985-0.87438 0.57232-0.58292 0.86378-1.2877 0.87438-2.1144 0.010599-0.82666-0.28086-1.5262-0.87438-2.0985-0.59352-0.57232-1.293-0.86378-2.0985-0.87438-0.8055-0.010599-1.5103 0.28086-2.1144 0.87438-0.60414 0.59352-0.8956 1.293-0.87438 2.0985 0.021197 0.8055 0.31266 1.5103 0.87438 2.1144 0.56172 0.60414 1.2665 0.8956 2.1144 0.87438zm4.4695 0.2115 3.681 3.6819-1.259 1.284-3.6817-3.7 0.0019784-0.69479-0.090043-0.098846c-0.87973 0.76087-1.92 1.1413-3.1207 1.1413-1.3553 0-2.5025-0.46363-3.4417-1.3909s-1.4088-2.0686-1.4088-3.4239c0-1.3553 0.4696-2.4966 1.4088-3.4239 0.9392-0.92727 2.0864-1.3969 3.4417-1.4088 1.3553-0.011889 2.4906 0.45771 3.406 1.4088 0.9154 0.95107 1.379 2.0924 1.3909 3.4239 0 1.2126-0.38043 2.2588-1.1413 3.1385l0.098834 0.090049z"></path></svg></button>
                      </div>
                  </form>
                </div>
              </div>

              <div className="slogan">
                <p>#1 Provider of solar PV certification testing services!</p>
              </div>

              <div className="header_mid">

                <nav>
                  <div className="mid_nav">
                    {menu}
                  </div>
                </nav>
              </div>
          </div>
          </div>

          <div className="header_bot">
            <nav>
              <div className="main_nav">
                <ul>
                  <li><NavLink to="/pv-modules">PV Modules</NavLink></li>
                  <li><NavLink to="/pv-systems">PV Systems & BOS</NavLink></li>
                  <li><NavLink to="/racks-trackers">Racks & Trackers</NavLink></li>
                  <li><NavLink to="/it-solutions">IT Solutions</NavLink></li>
                </ul>
              </div>
            </nav>
          </div>

        </div>
      </div>

    );
  }
}

export default Nav;