import React, { Component } from 'react';
import './SDF.css';
// import {NavLink} from "react-router-dom";
// import LoginForm from './components/LoginForm';
import SDFQuestionForm from './SDFQuestionForm';
import SDFRegistration from './SDFRegistration';
import SDFResults from './SDFResults';

class SDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      username: '',
      page: localStorage.getItem('page') ? localStorage.getItem('page') : 1,
      standard: '<not assigned yet>',
      baseModel: '<not assigned yet>',
      newModel: '<not assigned yet>',
      responseID: '<not assigned yet>',
      questionList: [],
      res: {},
      message: '',
      result: false
    };
  }

  componentDidMount() {
    if (this.state.logged_in) {
      fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/core/current_user/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        // .then(this.handleErrors)
        .then(json => {
          this.setState({ username: json.username });
        })
        // .catch(localStorage.removeItem('token'))
        .catch(error => console.log(error))
    }
  }

  // addQuestion (q) {
  //   var joined = this.state.questionList.concat(q)
  //   this.setState( {
  //     questionList: joined
  //   })
  // }
  validateResponses () {
    for (let key in this.state.res){
      console.log(key)
    }
    this.setState({message : "validated"})
  }

  handleErrors(response) {
    if (!response.ok) {
      localStorage.removeItem('token');
    }
    return response;
  }

  changeHandler = event => {
      
    const name = event.target.name;
    const value = event.target.value;
  
    this.setState({
        [name]: value
        });
    console.log("Name: "+ name + " Value: "+ value)
    console.log('-->' + this.state.standard + ' ' + this.state.baseModel + ' ' + this.state.newModel)

  }

  changeHandlerQuestions = event => {
    console.log('Handle onChange...')
    const type = event.target.type;
    const name = event.target.name;
    var value = event.target.value;
    if(type == 'checkbox'){
      value = event.target.checked;
    }
    
    console.log('type: '+type+'name'+name+'value'+value)
    // const reponse = this.state.[name]
    // this.setState({name:name})

    // const resObject = {
    //   [name]: {
    //     ...this.state[name],
    //     [type]:value
    //   }
    //   }
    

    
    this.setState({
          res: {...this.state.res,
            [name]: {
              ...this.state.res[name],
              [type]:value
            }}
        });
  }

  handleSubmitQuestions = event => {
    // this.gotoPage2()
    this.validateResponses();

    var check,num,tex

    for (let key in this.state.res){
      console.log(key)
      console.log(this.state.res[key])
      console.log(this.state.res[key].checkbox) 
      console.log(this.state.res[key].number)
      console.log(this.state.res[key].text)

      check = this.state.res[key].checkbox
      num = this.state.res[key].number
      tex = this.state.res[key].text

      if (check === undefined){check = 0} else {check = 1}
      if (num === undefined){num = null}
      if (tex === undefined){tex = null}
      console.log(JSON.stringify({responseID: this.state.responseID,
                  questionID: key,
                  responseApplicable: check,
                  responseValue: parseFloat(num),
                  responseComments: tex
                }))
      fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/ResponseDetail/', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization' : `JWT ${localStorage.getItem('token')}`
            },
            body : JSON.stringify({responseID: this.state.responseID,questionID: key,responseApplicable: check,responseValue: parseFloat(num),responseComments: tex})
        })
            .then(response => response.json())
            .then(data => {
                console.log(data) // Prints result from `response.json()` in getRequest
                // this.setState({responseID: data.responseID})
                // alert('The ResponseID is '+ data.responseID)
                this.setState({result: true})
                this.gotoPage3()
            })
            .catch(error => {console.error(error);
                this.setState({message: error.toString()})})
    
    }

    event.preventDefault();
  }

  gotoPage1 = event => {
    this.setState({
      page: 1
    })
    localStorage.setItem('page',1)
  }

  gotoPage2 = event => {
    this.setState({
      page: 2
    })
    localStorage.setItem('page',2)
  }

  gotoPage3 = event => {
    this.setState({
      page: 3
    })
    localStorage.setItem('page',3)
  }

  handleSubmit1 = event => {
    console.log('->>>>>>'+this.state.standard + ' ' + this.state.baseModel + ' ' + this.state.newModel);
    // alert('The standard is : '+ this.state.standard + ' ' + this.state.baseModel + ' ' + this.state.newModel);
    // const data = new FormData(event.target);
    // console.log(data);
    console.log("Submitted...");
    fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/api/ResponseHeader/', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization' : `JWT ${localStorage.getItem('token')}`
            },
            body : JSON.stringify({standardID: this.state.standard,
              baseModelID: this.state.baseModel,
              newModelID: this.state.newModel})
        })
            .then(response => response.json())
            .then(data => {
                console.log(data) // Prints result from `response.json()` in getRequest
                this.setState({responseID: data.responseID})
                alert('The ResponseID is '+ data.responseID)
                this.gotoPage2()
            })
            .catch(error => {console.error(error);
                this.setState({message: error.toString()})})
    
    event.preventDefault();
  }
  
  render() {
    let form;
    if(!this.state.logged_in){
      form = <li>Please log-in to view this content.</li>
    } else{
      form = <div class="dashboard-main"><div class="form-inline">{this.state.logged_in
        ? `Hello, ${this.state.username}`
        : ''}</div><div className='page'>
        <div className="page_title">
          <h1>Similarity Declaration Form</h1>
          <button onClick={this.gotoPage1}>Register</button>
          <button onClick={this.gotoPage2}>Questionnaire</button>
          <button onClick={this.gotoPage3}>Results</button>
            <p>Page - {this.state.page}
            <b>Standard - </b>{this.state.standard}
            <b>BaseModel - </b>{this.state.baseModel}
            <b>NewModel - </b>{this.state.newModel}
            <b>ResponseID - </b>{this.state.responseID}</p>
        </div>
      </div></div>;
    }

    let content;

    switch(this.state.page){
      case 1:
        content = <SDFRegistration 
        changeHandler={this.changeHandler} 
        nextPage={this.gotoPage2} 
        handleSubmit={this.handleSubmit1}
        standard_val={this.state.standard}
        baseModel_val={this.state.baseModel}
        newModel_val={this.state.newModel}/>
        break;
      case 2:
        content = <SDFQuestionForm 
        resObject={this.state.res} 
        message={this.state.message} 
        handleSubmit={this.handleSubmitQuestions} 
        addQuestion={this.addQuestion} 
        standard={this.state.standard} 
        changeHandler = {this.changeHandlerQuestions} />
        break;
      case 3:
        content = <SDFResults result={this.state.result} 
        responseID={this.state.responseID}/>
        break;
      default:
        this.setState({page:1})
        content = <SDFRegistration/>
        break;
    }

    return (
      <div className="sdf-body">
        
        {form}
        
        {content}
        
      </div>
    );
  }
}
export default SDF;
