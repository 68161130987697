import React, { Component } from "react";
import MapContainer from './GoogleMaps';
import './ContactUs.css';

class ContactUs extends Component {
  render() {
    return (
      <div className="page">
        <div className="contact_page_title ">
          <h1>Contact Us!</h1>
        </div>

        <div className="contact_section_a">
          <div className="text">
              <h3>SOLARPTL, LLC</h3>
              <p><strong>Address:</strong><br/>
                1107 West Fairmont Drive, Building A<br/>
                Tempe, AZ 85282</p>

              <p><strong>Phone:</strong><br/>(480) 966-1700</p>

                <p><strong>Email:</strong><br/>info@solarptl.com</p>
          </div>

          <div className="map">
            <MapContainer/>
          </div>

        </div>

        <div className="contact_section_b">
          <div className="text">
            <h3>Want to visit? No problem!</h3>
            <h4>Visitor Rules</h4>
            <p>Before sign-in, the visitor shall read and agree the following rules:</p>
            <ol>
              <li><p>Each visitor or group of visitors shall have a PTL staff member escort.</p></li>
              <li><p>Visitors shall follow the common laboratory safety rules as well as the specific requirements given by the PTL escort.</p></li>
              <li><p>No cameras are permitted inside the PTL facility without the approval of the PTL escort.</p></li>
              <li><p>The documentations, samples, and equipment in PTL may contain propriety information and therefore shall not be divulged without PTL's permission.</p></li>
          </ol>
          </div>
          <div className="contact_form">
            <form method="get">
              <label>Name:</label><br/>
              <input className="contact_field" type="text" required></input><br/>
              <label>Email:</label><br/>
              <input className="contact_field" type="email" required></input><br/>
              <label>Telephone:</label><br/>
              <input className="contact_field" type="tel"></input><br/>
              <label>Brief Description:</label><br/>
              <textarea name="description" rows="5" required></textarea><br/>
              <input className="contact_submit" type="submit" value="Submit"></input>
            </form>
          </div>
        </div>

      </div>
    )
  }
}

export default ContactUs;
