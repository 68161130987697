import React, { Component } from 'react';
import './SDF.css';
// import {NavLink} from "react-router-dom";
// import LoginForm from './components/LoginForm';

class SDFResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      username: '',
      data: [],
      message: [],
      responseid_id: 1
    };
  }

  componentDidMount() {
    if (this.state.logged_in) {
      this.fetchUser()
      this.fetchQuestions()
    }
  }

  fetchUser () {
    fetch('https://dashboard.ffutche.org/home/dashboard/dashboard/core/current_user/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(json => {
          this.setState({ username: json.username });
        })
        // .catch(localStorage.removeItem('token'))
        .catch(error => console.log(error))
  }
  

  fetchQuestions() {
    fetch(`https://dashboard.ffutche.org/home/dashboard/dashboard/api/Results/${this.props.responseID}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.json())
      // .then(this.handleErrors)
      .then(res => {
          //console.log(data) // Prints result from `response.json()` in getRequest
          this.setState({data: res})
      })
      .then(data => {console.log(data)});
      // .catch(error => console.error(error));
  }

  handleErrors(response) {
    if (!response.ok) {
      localStorage.removeItem('token');
    }
    return response;
  }

  onSubmit = (e) => {
    e.preventDefault();
    // get our form data out of state
    // const { fname, lname, email } = this.state;

    // axios.post('/', { fname, lname, email })
    //   .then((result) => {
    //     //access the results here....
    //   });
  }
  
  render() {
    let items = this.state.data
    console.log("Hello")
    console.log(items.length)
    console.log(localStorage.getItem('token'))

    // let message = "HelloWorld"
    let form
    // if(items.length>0){
    //   body = items.map(item => 
    //     <tr  className="sdfQuestionRow" >
    //       <td>
    //         <label for={item.questionGroupID+"-"+item.questionNum}>{item.questionText}</label>
    //       </td>
    //       <td>
    //         <input type="checkbox" name={item.questionGroupID+"-"+item.questionNum} />
    //       </td>
    //       <td>
    //         <input type="text" name={item.questionGroupID+"-"+item.questionNum} />
    //       </td>
    //     </tr>
    // )
    // form = <form onSubmit={this.onSubmit}>
    //   <tr className="sdfQuestionHeader">
    //       <td>
    //         Question
    //       </td>
    //       <td>
    //         Is applicable?
    //       </td>
    //       <td>
    //         Value
    //       </td>
    //     </tr>
    //   {body}
    //   <button type="submit">Submit</button></form>
    // } else {
    //   form = 
    //   <div>Unable to fetch</div>
    //   }
    
    if(this.props.result){
    if(items.length!==0){
      var formattedString;
      if(items.results !== undefined){
        formattedString = items.results.split("||").map((item, i) => {
          return <p key={i}>{item}</p>;
      });
      } else {
        formattedString = "No Retest Required"
      }
      
      form = <div className='results'>
        <h2>Results</h2>
        Based on your inputs perform the following tests:
        <div className='resultOutput'>{formattedString}</div>
      </div>
    } else {
      form = <div>Unable to fetch</div>
    }
  } else {
    form = <b>Results not ready submit page 2</b>
  }
    
    

    return (
      <div>
          {form}
      </div>
    );
  }
}
export default SDFResults;
